export const FastSpringPrice = {
  page: 1,
  limit: 50,
  products: [
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-one-year-multi-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 138.6,
          display: "$138.60",
          quantityDiscount: {
            1: {
              discountValue: 56.7,
              discountValueDisplay: "$56.70",
              unitPrice: 81.9,
              unitPriceDisplay: "$81.90",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 132,
          display: "132,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "54,00 US$",
              unitPrice: 78,
              unitPriceDisplay: "78,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 132,
          display: "US$ 132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$ 54,00",
              unitPrice: 78,
              unitPriceDisplay: "US$ 78,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€ 55,50",
              unitPrice: 91.5,
              unitPriceDisplay: "€ 91,50",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 147,
          display: "147,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 EUR",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 158.4,
          display: "158,40 $",
          quantityDiscount: {
            1: {
              discountValue: 64.8,
              discountValueDisplay: "64,80 $",
              unitPrice: 93.6,
              unitPriceDisplay: "93,60 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 132,
          display: "USD132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54,00",
              unitPrice: 78,
              unitPriceDisplay: "USD78,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 151.8,
          display: "$151.80",
          quantityDiscount: {
            1: {
              discountValue: 62.1,
              discountValueDisplay: "$62.10",
              unitPrice: 89.7,
              unitPriceDisplay: "$89.70",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 132,
          display: "US$ 132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$ 54,00",
              unitPrice: 78,
              unitPriceDisplay: "US$ 78,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 141.24,
          display: "US$141.24",
          quantityDiscount: {
            1: {
              discountValue: 57.78,
              discountValueDisplay: "US$57.78",
              unitPrice: 83.46,
              unitPriceDisplay: "US$83.46",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 158.4,
          display: "$158.40",
          quantityDiscount: {
            1: {
              discountValue: 64.8,
              discountValueDisplay: "$64.80",
              unitPrice: 93.6,
              unitPriceDisplay: "$93.60",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 132,
          display: "132,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "54,00 US$",
              unitPrice: 78,
              unitPriceDisplay: "78,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€ 55,50",
              unitPrice: 91.5,
              unitPriceDisplay: "€ 91,50",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 157.08,
          display: "US$157,08",
          quantityDiscount: {
            1: {
              discountValue: 64.26,
              discountValueDisplay: "US$64,26",
              unitPrice: 92.82,
              unitPriceDisplay: "US$92,82",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 157.08,
          display: "US$ 157,08",
          quantityDiscount: {
            1: {
              discountValue: 64.26,
              discountValueDisplay: "US$ 64,26",
              unitPrice: 92.82,
              unitPriceDisplay: "US$ 92,82",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 132,
          display: "USD132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54,00",
              unitPrice: 78,
              unitPriceDisplay: "USD78,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 132,
          display: "132,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "54,00 US$",
              unitPrice: 78,
              unitPriceDisplay: "78,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 141.24,
          display: "$141.24",
          quantityDiscount: {
            1: {
              discountValue: 57.78,
              discountValueDisplay: "$57.78",
              unitPrice: 83.46,
              unitPriceDisplay: "$83.46",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 155.76,
          display: "$155,76",
          quantityDiscount: {
            1: {
              discountValue: 63.72,
              discountValueDisplay: "$63,72",
              unitPrice: 92.04,
              unitPriceDisplay: "$92,04",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 138.6,
          display: "US$138.60",
          quantityDiscount: {
            1: {
              discountValue: 56.7,
              discountValueDisplay: "US$56.70",
              unitPrice: 81.9,
              unitPriceDisplay: "US$81.90",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 132,
          display: "$132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54,00",
              unitPrice: 78,
              unitPriceDisplay: "$78,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 132,
          display: "US$ 132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$ 54,00",
              unitPrice: 78,
              unitPriceDisplay: "US$ 78,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 132,
          display: "USD132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54,00",
              unitPrice: 78,
              unitPriceDisplay: "USD78,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 159,
          display: "A$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "A$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "A$91.50",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 147,
          display: "147,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 EUR",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 147,
          display: "147,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 EUR",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 146.52,
          display: "$146.52",
          quantityDiscount: {
            1: {
              discountValue: 59.94,
              discountValueDisplay: "$59.94",
              unitPrice: 86.58,
              unitPriceDisplay: "$86.58",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 155.76,
          display: "US$ 155.76",
          quantityDiscount: {
            1: {
              discountValue: 63.72,
              discountValueDisplay: "US$ 63.72",
              unitPrice: 92.04,
              unitPriceDisplay: "US$ 92.04",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 151.8,
          display: "US$151,80",
          quantityDiscount: {
            1: {
              discountValue: 62.1,
              discountValueDisplay: "US$62,10",
              unitPrice: 89.7,
              unitPriceDisplay: "US$89,70",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 145.2,
          display: "$145.20",
          quantityDiscount: {
            1: {
              discountValue: 59.4,
              discountValueDisplay: "$59.40",
              unitPrice: 85.8,
              unitPriceDisplay: "$85.80",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 145.2,
          display: "US$145.20",
          quantityDiscount: {
            1: {
              discountValue: 59.4,
              discountValueDisplay: "US$59.40",
              unitPrice: 85.8,
              unitPriceDisplay: "US$85.80",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€ 55,50",
              unitPrice: 91.5,
              unitPriceDisplay: "€ 91,50",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€ 55,50",
              unitPrice: 91.5,
              unitPriceDisplay: "€ 91,50",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 67.5,
              discountValueDisplay: "$67.50",
              unitPrice: 91.5,
              unitPriceDisplay: "$91.50",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 151.8,
          display: "US$151.80",
          quantityDiscount: {
            1: {
              discountValue: 62.1,
              discountValueDisplay: "US$62.10",
              unitPrice: 89.7,
              unitPriceDisplay: "US$89.70",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "55,50 €",
              unitPrice: 91.5,
              unitPriceDisplay: "91,50 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 55.5,
              discountValueDisplay: "€55.50",
              unitPrice: 91.5,
              unitPriceDisplay: "€91.50",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "ocr-m",
      pricing: {
        PR: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 4.2,
          display: "$4.20",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 4,
          display: "4,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 4,
          display: "US$ 4,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 5,
          display: "€ 5,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 5,
          display: "5,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 4.8,
          display: "4,80 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 4,
          display: "USD4,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 4.6,
          display: "$4.60",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 4,
          display: "US$ 4,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 4.28,
          display: "US$4.28",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 4.8,
          display: "$4.80",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 4,
          display: "4,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 5,
          display: "€ 5,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 4.76,
          display: "US$4,76",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 4,
          display: "US$ 4,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 4,
          display: "USD4,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 4,
          display: "4,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 4.28,
          display: "$4.28",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 4.72,
          display: "$4,72",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 4.2,
          display: "US$4.20",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 4,
          display: "$4,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 4,
          display: "US$ 4,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 4,
          display: "USD4,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 4,
          display: "£4.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 4,
          display: "£4.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 4,
          display: "USD4.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 7,
          display: "A$7.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 5,
          display: "5,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 5,
          display: "5,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 4,
          display: "4,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 4.44,
          display: "$4.44",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 4,
          display: "£4.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 4.72,
          display: "US$ 4.72",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 4.6,
          display: "US$4,60",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 4,
          display: "£4.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 4.4,
          display: "$4.40",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 4.4,
          display: "US$4.40",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 4,
          display: "USD4.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 4.24,
          display: "US$4.24",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 5,
          display: "€ 5,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 5,
          display: "€ 5,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 4.6,
          display: "US$4.60",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 4,
          display: "USD4.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 4,
          display: "USD4.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 4,
          display: "$4.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 4,
          display: "€4.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 4,
          display: "US$4.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-multi-ocr-uk",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 10,
          display: "A$10.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "example-pro",
      pricing: {
        PR: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 72,
          display: "72,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 63,
          display: "$63.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 60,
          display: "60,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 60,
          display: "US$ 60,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 71,
          display: "€ 71,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 102,
          display: "$102.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 70,
          display: "€70.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 71,
          display: "71,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 71,
          display: "71,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 72,
          display: "72,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 71,
          display: "€71.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 60,
          display: "USD60,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 69,
          display: "$69.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 60,
          display: "US$ 60,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 73,
          display: "73,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 64.2,
          display: "US$64.20",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 72,
          display: "72,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 72,
          display: "$72.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 71,
          display: "71,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 85,
          display: "$85.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 93,
          display: "$93.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 60,
          display: "60,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 63,
          display: "€ 63,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 71.4,
          display: "US$71,40",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 71.4,
          display: "US$ 71,40",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 60,
          display: "USD60,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 60,
          display: "60,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 64.2,
          display: "$64.20",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 93,
          display: "$93.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 70,
          display: "€70.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 71,
          display: "71,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 70.8,
          display: "$70,80",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 70,
          display: "70,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 93,
          display: "$93.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 63,
          display: "US$63.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 73,
          display: "73,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 60,
          display: "$60,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 71,
          display: "€71.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 60,
          display: "US$ 60,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 71,
          display: "71,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 60,
          display: "USD60,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 73,
          display: "73,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 71,
          display: "71,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 62,
          display: "£62.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 52,
          display: "£52.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 73,
          display: "73,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 52,
          display: "£52.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 60,
          display: "USD60.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 93,
          display: "A$93.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 73,
          display: "73,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 75,
          display: "75,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 59,
          display: "59,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 66.6,
          display: "$66.60",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 72,
          display: "€72.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 52,
          display: "£52.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 70.8,
          display: "US$ 70.80",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 69,
          display: "US$69,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 73,
          display: "€73.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 72,
          display: "72,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 52,
          display: "£52.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 66,
          display: "$66.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 93,
          display: "$93.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 66,
          display: "US$66.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 71,
          display: "71,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 69,
          display: "€69.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 71,
          display: "71,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 69,
          display: "€69.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 60,
          display: "USD60.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 63.6,
          display: "US$63.60",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 93,
          display: "$93.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 71,
          display: "€ 71,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 73,
          display: "€ 73,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 93,
          display: "$93.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 69,
          display: "US$69.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 60,
          display: "USD60.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 60,
          display: "USD60.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 60,
          display: "$60.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 72,
          display: "72,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 59,
          display: "€59.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 60,
          display: "US$60.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-multi-australia",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 8,
          display: "A$8.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-multi-ocr-australia",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 10,
          display: "A$10.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual",
      pricing: {
        PR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 201.6,
          display: "$201.60",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 230.4,
          display: "230,40 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 220.8,
          display: "$220.80",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 205.44,
          display: "US$205.44",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 230.4,
          display: "$230.40",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 228.48,
          display: "US$228,48",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 228.48,
          display: "US$ 228,48",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 205.44,
          display: "$205.44",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 226.56,
          display: "$226,56",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 201.6,
          display: "US$201.60",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 192,
          display: "$192,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 249,
          display: "A$249.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 213.12,
          display: "$213.12",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 226.56,
          display: "US$ 226.56",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 220.8,
          display: "US$220,80",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 211.2,
          display: "$211.20",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 211.2,
          display: "US$211.20",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 203.52,
          display: "US$203.52",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 220.8,
          display: "US$220.80",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-ocr-australia",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 10,
          display: "A$10.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly",
      pricing: {
        PR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 15.75,
          display: "$15.75",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 15,
          display: "15,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 15,
          display: "US$ 15,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 15,
          display: "15,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 18,
          display: "18,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 15,
          display: "USD15,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 17.25,
          display: "$17.25",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 15,
          display: "US$ 15,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 16.05,
          display: "US$16.05",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 18,
          display: "$18.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 15,
          display: "15,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 17.85,
          display: "US$17,85",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 17.85,
          display: "US$ 17,85",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 15,
          display: "USD15,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 15,
          display: "15,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 16.05,
          display: "$16.05",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 17.7,
          display: "$17,70",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 15.75,
          display: "US$15.75",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 15,
          display: "$15,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 15,
          display: "US$ 15,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 15,
          display: "USD15,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 17,
          display: "A$17.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 15,
          display: "15,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 15,
          display: "15,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 16.65,
          display: "$16.65",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 17.7,
          display: "US$ 17.70",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 17.25,
          display: "US$17,25",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 16.5,
          display: "$16.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 16.5,
          display: "US$16.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 17.25,
          display: "US$17.25",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-two-year-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 316,
          display: "316,00 €",
          quantityDiscount: {
            1: {
              discountValue: 157.86,
              discountValueDisplay: "157,86 €",
              unitPrice: 158.14,
              unitPriceDisplay: "158,14 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 277.2,
          display: "$277.20",
          quantityDiscount: {
            1: {
              discountValue: 138.6,
              discountValueDisplay: "$138.60",
              unitPrice: 138.6,
              unitPriceDisplay: "$138.60",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 264,
          display: "264,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "132,00 US$",
              unitPrice: 132,
              unitPriceDisplay: "132,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 264,
          display: "US$ 264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$ 132,00",
              unitPrice: 132,
              unitPriceDisplay: "US$ 132,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 309,
          display: "€ 309,00",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "€ 154,01",
              unitPrice: 154.99,
              unitPriceDisplay: "€ 154,99",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 446,
          display: "$446.00",
          quantityDiscount: {
            1: {
              discountValue: 222.87,
              discountValueDisplay: "$222.87",
              unitPrice: 223.13,
              unitPriceDisplay: "$223.13",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 306,
          display: "€306.00",
          quantityDiscount: {
            1: {
              discountValue: 152.72,
              discountValueDisplay: "€152.72",
              unitPrice: 153.28,
              unitPriceDisplay: "€153.28",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 309,
          display: "309,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "154,01 EUR",
              unitPrice: 154.99,
              unitPriceDisplay: "154,99 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 316.8,
          display: "316,80 $",
          quantityDiscount: {
            1: {
              discountValue: 158.4,
              discountValueDisplay: "158,40 $",
              unitPrice: 158.4,
              unitPriceDisplay: "158,40 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 309,
          display: "€309.00",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "€154.01",
              unitPrice: 154.99,
              unitPriceDisplay: "€154.99",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 264,
          display: "USD264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132,00",
              unitPrice: 132,
              unitPriceDisplay: "USD132,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 303.6,
          display: "$303.60",
          quantityDiscount: {
            1: {
              discountValue: 151.8,
              discountValueDisplay: "$151.80",
              unitPrice: 151.8,
              unitPriceDisplay: "$151.80",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 264,
          display: "US$ 264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$ 132,00",
              unitPrice: 132,
              unitPriceDisplay: "US$ 132,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 321,
          display: "321,00 €",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "160,42 €",
              unitPrice: 160.58,
              unitPriceDisplay: "160,58 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 282.48,
          display: "US$282.48",
          quantityDiscount: {
            1: {
              discountValue: 141.24,
              discountValueDisplay: "US$141.24",
              unitPrice: 141.24,
              unitPriceDisplay: "US$141.24",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 314,
          display: "314,00 €",
          quantityDiscount: {
            1: {
              discountValue: 156.57,
              discountValueDisplay: "156,57 €",
              unitPrice: 157.43,
              unitPriceDisplay: "157,43 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 316.8,
          display: "$316.80",
          quantityDiscount: {
            1: {
              discountValue: 158.4,
              discountValueDisplay: "$158.40",
              unitPrice: 158.4,
              unitPriceDisplay: "$158.40",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 309,
          display: "309,00 €",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "154,01 €",
              unitPrice: 154.99,
              unitPriceDisplay: "154,99 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 370,
          display: "$370.00",
          quantityDiscount: {
            1: {
              discountValue: 184.9,
              discountValueDisplay: "$184.90",
              unitPrice: 185.1,
              unitPriceDisplay: "$185.10",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 264,
          display: "264,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "132,00 US$",
              unitPrice: 132,
              unitPriceDisplay: "132,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 277,
          display: "€ 277,00",
          quantityDiscount: {
            1: {
              discountValue: 138.22,
              discountValueDisplay: "€ 138,22",
              unitPrice: 138.78,
              unitPriceDisplay: "€ 138,78",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 314.16,
          display: "US$314,16",
          quantityDiscount: {
            1: {
              discountValue: 157.08,
              discountValueDisplay: "US$157,08",
              unitPrice: 157.08,
              unitPriceDisplay: "US$157,08",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 314.16,
          display: "US$ 314,16",
          quantityDiscount: {
            1: {
              discountValue: 157.08,
              discountValueDisplay: "US$ 157,08",
              unitPrice: 157.08,
              unitPriceDisplay: "US$ 157,08",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 264,
          display: "USD264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132,00",
              unitPrice: 132,
              unitPriceDisplay: "USD132,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 264,
          display: "264,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "132,00 US$",
              unitPrice: 132,
              unitPriceDisplay: "132,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 282.48,
          display: "$282.48",
          quantityDiscount: {
            1: {
              discountValue: 141.24,
              discountValueDisplay: "$141.24",
              unitPrice: 141.24,
              unitPriceDisplay: "$141.24",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 306,
          display: "€306.00",
          quantityDiscount: {
            1: {
              discountValue: 152.72,
              discountValueDisplay: "€152.72",
              unitPrice: 153.28,
              unitPriceDisplay: "€153.28",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 311.52,
          display: "$311,52",
          quantityDiscount: {
            1: {
              discountValue: 155.76,
              discountValueDisplay: "$155,76",
              unitPrice: 155.76,
              unitPriceDisplay: "$155,76",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 306,
          display: "306,00 €",
          quantityDiscount: {
            1: {
              discountValue: 152.72,
              discountValueDisplay: "152,72 €",
              unitPrice: 153.28,
              unitPriceDisplay: "153,28 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 277.2,
          display: "US$277.20",
          quantityDiscount: {
            1: {
              discountValue: 138.6,
              discountValueDisplay: "US$138.60",
              unitPrice: 138.6,
              unitPriceDisplay: "US$138.60",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 321,
          display: "321,00 €",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "160,42 €",
              unitPrice: 160.58,
              unitPriceDisplay: "160,58 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 264,
          display: "$264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132,00",
              unitPrice: 132,
              unitPriceDisplay: "$132,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 309,
          display: "€309.00",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "€154.01",
              unitPrice: 154.99,
              unitPriceDisplay: "€154.99",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 264,
          display: "US$ 264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$ 132,00",
              unitPrice: 132,
              unitPriceDisplay: "US$ 132,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 264,
          display: "USD264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132,00",
              unitPrice: 132,
              unitPriceDisplay: "USD132,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 319,
          display: "319,00 €",
          quantityDiscount: {
            1: {
              discountValue: 159.14,
              discountValueDisplay: "159,14 €",
              unitPrice: 159.86,
              unitPriceDisplay: "159,86 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 309,
          display: "309,00 €",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "154,01 €",
              unitPrice: 154.99,
              unitPriceDisplay: "154,99 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 273,
          display: "£273.00",
          quantityDiscount: {
            1: {
              discountValue: 136.02,
              discountValueDisplay: "£136.02",
              unitPrice: 136.98,
              unitPriceDisplay: "£136.98",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 319,
          display: "319,00 €",
          quantityDiscount: {
            1: {
              discountValue: 159.14,
              discountValueDisplay: "159,14 €",
              unitPrice: 159.86,
              unitPriceDisplay: "159,86 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 406,
          display: "A$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "A$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "A$203.39",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 321,
          display: "321,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "160,42 EUR",
              unitPrice: 160.58,
              unitPriceDisplay: "160,58 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 326,
          display: "326,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 162.99,
              discountValueDisplay: "162,99 EUR",
              unitPrice: 163.01,
              unitPriceDisplay: "163,01 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 257,
          display: "257,00 €",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "128,34 €",
              unitPrice: 128.66,
              unitPriceDisplay: "128,66 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 293.04,
          display: "$293.04",
          quantityDiscount: {
            1: {
              discountValue: 146.52,
              discountValueDisplay: "$146.52",
              unitPrice: 146.52,
              unitPriceDisplay: "$146.52",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 316,
          display: "€316.00",
          quantityDiscount: {
            1: {
              discountValue: 157.86,
              discountValueDisplay: "€157.86",
              unitPrice: 158.14,
              unitPriceDisplay: "€158.14",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 311.52,
          display: "US$ 311.52",
          quantityDiscount: {
            1: {
              discountValue: 155.76,
              discountValueDisplay: "US$ 155.76",
              unitPrice: 155.76,
              unitPriceDisplay: "US$ 155.76",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 303.6,
          display: "US$303,60",
          quantityDiscount: {
            1: {
              discountValue: 151.8,
              discountValueDisplay: "US$151,80",
              unitPrice: 151.8,
              unitPriceDisplay: "US$151,80",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 319,
          display: "€319.00",
          quantityDiscount: {
            1: {
              discountValue: 159.14,
              discountValueDisplay: "€159.14",
              unitPrice: 159.86,
              unitPriceDisplay: "€159.86",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 314,
          display: "314,00 €",
          quantityDiscount: {
            1: {
              discountValue: 156.57,
              discountValueDisplay: "156,57 €",
              unitPrice: 157.43,
              unitPriceDisplay: "157,43 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 290.4,
          display: "$290.40",
          quantityDiscount: {
            1: {
              discountValue: 145.2,
              discountValueDisplay: "$145.20",
              unitPrice: 145.2,
              unitPriceDisplay: "$145.20",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 290.4,
          display: "US$290.40",
          quantityDiscount: {
            1: {
              discountValue: 145.2,
              discountValueDisplay: "US$145.20",
              unitPrice: 145.2,
              unitPriceDisplay: "US$145.20",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 301,
          display: "€301.00",
          quantityDiscount: {
            1: {
              discountValue: 150.16,
              discountValueDisplay: "€150.16",
              unitPrice: 150.84,
              unitPriceDisplay: "€150.84",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 303,
          display: "€303.00",
          quantityDiscount: {
            1: {
              discountValue: 151.44,
              discountValueDisplay: "€151.44",
              unitPrice: 151.56,
              unitPriceDisplay: "€151.56",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 311,
          display: "€ 311,00",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "€ 155,29",
              unitPrice: 155.71,
              unitPriceDisplay: "€ 155,71",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 321,
          display: "€ 321,00",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "€ 160,42",
              unitPrice: 160.58,
              unitPriceDisplay: "€ 160,58",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 303.6,
          display: "US$303.60",
          quantityDiscount: {
            1: {
              discountValue: 151.8,
              discountValueDisplay: "US$151.80",
              unitPrice: 151.8,
              unitPriceDisplay: "US$151.80",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 316,
          display: "316,00 €",
          quantityDiscount: {
            1: {
              discountValue: 157.86,
              discountValueDisplay: "157,86 €",
              unitPrice: 158.14,
              unitPriceDisplay: "158,14 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-one-year",
      pricing: {
        PR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 100.8,
          display: "$100.80",
          quantityDiscount: {
            1: {
              discountValue: 37.8,
              discountValueDisplay: "$37.80",
              unitPrice: 63,
              unitPriceDisplay: "$63.00",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 96,
          display: "96,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 US$",
              unitPrice: 60,
              unitPriceDisplay: "60,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 96,
          display: "US$ 96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$ 36,00",
              unitPrice: 60,
              unitPriceDisplay: "US$ 60,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 108,
          display: "108,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 72,
              unitPriceDisplay: "72,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 115.2,
          display: "115,20 $",
          quantityDiscount: {
            1: {
              discountValue: 43.2,
              discountValueDisplay: "43,20 $",
              unitPrice: 72,
              unitPriceDisplay: "72,00 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 96,
          display: "USD96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36,00",
              unitPrice: 60,
              unitPriceDisplay: "USD60,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 110.4,
          display: "$110.40",
          quantityDiscount: {
            1: {
              discountValue: 41.4,
              discountValueDisplay: "$41.40",
              unitPrice: 69,
              unitPriceDisplay: "$69.00",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 96,
          display: "US$ 96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$ 36,00",
              unitPrice: 60,
              unitPriceDisplay: "US$ 60,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 102.72,
          display: "US$102.72",
          quantityDiscount: {
            1: {
              discountValue: 38.52,
              discountValueDisplay: "US$38.52",
              unitPrice: 64.2,
              unitPriceDisplay: "US$64.20",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 115.2,
          display: "$115.20",
          quantityDiscount: {
            1: {
              discountValue: 43.2,
              discountValueDisplay: "$43.20",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 96,
          display: "96,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 US$",
              unitPrice: 60,
              unitPriceDisplay: "60,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 114.24,
          display: "US$114,24",
          quantityDiscount: {
            1: {
              discountValue: 42.84,
              discountValueDisplay: "US$42,84",
              unitPrice: 71.4,
              unitPriceDisplay: "US$71,40",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 114.24,
          display: "US$ 114,24",
          quantityDiscount: {
            1: {
              discountValue: 42.84,
              discountValueDisplay: "US$ 42,84",
              unitPrice: 71.4,
              unitPriceDisplay: "US$ 71,40",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 96,
          display: "USD96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36,00",
              unitPrice: 60,
              unitPriceDisplay: "USD60,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 96,
          display: "96,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 US$",
              unitPrice: 60,
              unitPriceDisplay: "60,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 102.72,
          display: "$102.72",
          quantityDiscount: {
            1: {
              discountValue: 38.52,
              discountValueDisplay: "$38.52",
              unitPrice: 64.2,
              unitPriceDisplay: "$64.20",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 113.28,
          display: "$113,28",
          quantityDiscount: {
            1: {
              discountValue: 42.48,
              discountValueDisplay: "$42,48",
              unitPrice: 70.8,
              unitPriceDisplay: "$70,80",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 100.8,
          display: "US$100.80",
          quantityDiscount: {
            1: {
              discountValue: 37.8,
              discountValueDisplay: "US$37.80",
              unitPrice: 63,
              unitPriceDisplay: "US$63.00",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 96,
          display: "$96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36,00",
              unitPrice: 60,
              unitPriceDisplay: "$60,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 96,
          display: "US$ 96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$ 36,00",
              unitPrice: 60,
              unitPriceDisplay: "US$ 60,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 96,
          display: "USD96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36,00",
              unitPrice: 60,
              unitPriceDisplay: "USD60,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 120,
          display: "A$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "A$48.00",
              unitPrice: 72,
              unitPriceDisplay: "A$72.00",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 108,
          display: "108,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 72,
              unitPriceDisplay: "72,00 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 108,
          display: "108,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 72,
              unitPriceDisplay: "72,00 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 106.56,
          display: "$106.56",
          quantityDiscount: {
            1: {
              discountValue: 39.96,
              discountValueDisplay: "$39.96",
              unitPrice: 66.6,
              unitPriceDisplay: "$66.60",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 113.28,
          display: "US$ 113.28",
          quantityDiscount: {
            1: {
              discountValue: 42.48,
              discountValueDisplay: "US$ 42.48",
              unitPrice: 70.8,
              unitPriceDisplay: "US$ 70.80",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 110.4,
          display: "US$110,40",
          quantityDiscount: {
            1: {
              discountValue: 41.4,
              discountValueDisplay: "US$41,40",
              unitPrice: 69,
              unitPriceDisplay: "US$69,00",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 105.6,
          display: "$105.60",
          quantityDiscount: {
            1: {
              discountValue: 39.6,
              discountValueDisplay: "$39.60",
              unitPrice: 66,
              unitPriceDisplay: "$66.00",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 105.6,
          display: "US$105.60",
          quantityDiscount: {
            1: {
              discountValue: 39.6,
              discountValueDisplay: "US$39.60",
              unitPrice: 66,
              unitPriceDisplay: "US$66.00",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 110.4,
          display: "US$110.40",
          quantityDiscount: {
            1: {
              discountValue: 41.4,
              discountValueDisplay: "US$41.40",
              unitPrice: 69,
              unitPriceDisplay: "US$69.00",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-australia",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 8,
          display: "A$8.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-one-year-multi",
      pricing: {
        PR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 100.8,
          display: "$100.80",
          quantityDiscount: {
            1: {
              discountValue: 37.8,
              discountValueDisplay: "$37.80",
              unitPrice: 63,
              unitPriceDisplay: "$63.00",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 96,
          display: "96,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 US$",
              unitPrice: 60,
              unitPriceDisplay: "60,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 96,
          display: "US$ 96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$ 36,00",
              unitPrice: 60,
              unitPriceDisplay: "US$ 60,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 108,
          display: "108,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 72,
              unitPriceDisplay: "72,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 115.2,
          display: "115,20 $",
          quantityDiscount: {
            1: {
              discountValue: 43.2,
              discountValueDisplay: "43,20 $",
              unitPrice: 72,
              unitPriceDisplay: "72,00 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 96,
          display: "USD96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36,00",
              unitPrice: 60,
              unitPriceDisplay: "USD60,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 110.4,
          display: "$110.40",
          quantityDiscount: {
            1: {
              discountValue: 41.4,
              discountValueDisplay: "$41.40",
              unitPrice: 69,
              unitPriceDisplay: "$69.00",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 96,
          display: "US$ 96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$ 36,00",
              unitPrice: 60,
              unitPriceDisplay: "US$ 60,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 102.72,
          display: "US$102.72",
          quantityDiscount: {
            1: {
              discountValue: 38.52,
              discountValueDisplay: "US$38.52",
              unitPrice: 64.2,
              unitPriceDisplay: "US$64.20",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 115.2,
          display: "$115.20",
          quantityDiscount: {
            1: {
              discountValue: 43.2,
              discountValueDisplay: "$43.20",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 96,
          display: "96,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 US$",
              unitPrice: 60,
              unitPriceDisplay: "60,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 114.24,
          display: "US$114,24",
          quantityDiscount: {
            1: {
              discountValue: 42.84,
              discountValueDisplay: "US$42,84",
              unitPrice: 71.4,
              unitPriceDisplay: "US$71,40",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 114.24,
          display: "US$ 114,24",
          quantityDiscount: {
            1: {
              discountValue: 42.84,
              discountValueDisplay: "US$ 42,84",
              unitPrice: 71.4,
              unitPriceDisplay: "US$ 71,40",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 96,
          display: "USD96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36,00",
              unitPrice: 60,
              unitPriceDisplay: "USD60,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 96,
          display: "96,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 US$",
              unitPrice: 60,
              unitPriceDisplay: "60,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 102.72,
          display: "$102.72",
          quantityDiscount: {
            1: {
              discountValue: 38.52,
              discountValueDisplay: "$38.52",
              unitPrice: 64.2,
              unitPriceDisplay: "$64.20",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 113.28,
          display: "$113,28",
          quantityDiscount: {
            1: {
              discountValue: 42.48,
              discountValueDisplay: "$42,48",
              unitPrice: 70.8,
              unitPriceDisplay: "$70,80",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 100.8,
          display: "US$100.80",
          quantityDiscount: {
            1: {
              discountValue: 37.8,
              discountValueDisplay: "US$37.80",
              unitPrice: 63,
              unitPriceDisplay: "US$63.00",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 96,
          display: "$96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36,00",
              unitPrice: 60,
              unitPriceDisplay: "$60,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 96,
          display: "US$ 96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$ 36,00",
              unitPrice: 60,
              unitPriceDisplay: "US$ 60,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 96,
          display: "USD96,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36,00",
              unitPrice: 60,
              unitPriceDisplay: "USD60,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 120,
          display: "A$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "A$48.00",
              unitPrice: 72,
              unitPriceDisplay: "A$72.00",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 108,
          display: "108,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 72,
              unitPriceDisplay: "72,00 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 108,
          display: "108,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 72,
              unitPriceDisplay: "72,00 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 106.56,
          display: "$106.56",
          quantityDiscount: {
            1: {
              discountValue: 39.96,
              discountValueDisplay: "$39.96",
              unitPrice: 66.6,
              unitPriceDisplay: "$66.60",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 113.28,
          display: "US$ 113.28",
          quantityDiscount: {
            1: {
              discountValue: 42.48,
              discountValueDisplay: "US$ 42.48",
              unitPrice: 70.8,
              unitPriceDisplay: "US$ 70.80",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 110.4,
          display: "US$110,40",
          quantityDiscount: {
            1: {
              discountValue: 41.4,
              discountValueDisplay: "US$41,40",
              unitPrice: 69,
              unitPriceDisplay: "US$69,00",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 96,
          display: "£96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "£36.00",
              unitPrice: 60,
              unitPriceDisplay: "£60.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 105.6,
          display: "$105.60",
          quantityDiscount: {
            1: {
              discountValue: 39.6,
              discountValueDisplay: "$39.60",
              unitPrice: 66,
              unitPriceDisplay: "$66.00",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 105.6,
          display: "US$105.60",
          quantityDiscount: {
            1: {
              discountValue: 39.6,
              discountValueDisplay: "US$39.60",
              unitPrice: 66,
              unitPriceDisplay: "US$66.00",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 108,
          display: "€ 108,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 72,
              unitPriceDisplay: "€ 72,00",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 120,
          display: "$120.00",
          quantityDiscount: {
            1: {
              discountValue: 48,
              discountValueDisplay: "$48.00",
              unitPrice: 72,
              unitPriceDisplay: "$72.00",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 110.4,
          display: "US$110.40",
          quantityDiscount: {
            1: {
              discountValue: 41.4,
              discountValueDisplay: "US$41.40",
              unitPrice: 69,
              unitPriceDisplay: "US$69.00",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 96,
          display: "USD96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "USD36.00",
              unitPrice: 60,
              unitPriceDisplay: "USD60.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 96,
          display: "$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 60,
              unitPriceDisplay: "$60.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 108,
          display: "108,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 72,
              unitPriceDisplay: "72,00 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 108,
          display: "€108.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 72,
              unitPriceDisplay: "€72.00",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 96,
          display: "US$96.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "US$36.00",
              unitPrice: 60,
              unitPriceDisplay: "US$60.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-ocr-uk",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 10,
          display: "A$10.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "example",
      pricing: {
        PR: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 59.95,
          display: "59,95 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 52.45,
          display: "$52.45",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 49.95,
          display: "49,95 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 49.95,
          display: "US$ 49,95",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 58.95,
          display: "€ 58,95",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 84.95,
          display: "$84.95",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 57.95,
          display: "€57.95",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 59.94,
          display: "59,94 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 58.95,
          display: "€58.95",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 49.95,
          display: "USD49,95",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 57.44,
          display: "$57.44",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 49.95,
          display: "US$ 49,95",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 60.95,
          display: "60,95 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 53.45,
          display: "US$53.45",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 59.95,
          display: "59,95 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 59.94,
          display: "$59.94",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 70.95,
          display: "$70.95",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 76.95,
          display: "$76.95",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 49.95,
          display: "49,95 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 52.95,
          display: "€ 52,95",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 59.44,
          display: "US$59,44",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 59.44,
          display: "US$ 59,44",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 49.95,
          display: "USD49,95",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 49.95,
          display: "49,95 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 53.45,
          display: "$53.45",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 76.95,
          display: "$76.95",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 57.95,
          display: "€57.95",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 58.94,
          display: "$58,94",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 57.95,
          display: "57,95 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 76.95,
          display: "$76.95",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 52.45,
          display: "US$52.45",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 60.95,
          display: "60,95 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 49.95,
          display: "$49,95",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 58.95,
          display: "€58.95",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 49.95,
          display: "US$ 49,95",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 49.95,
          display: "USD49,95",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 60.95,
          display: "60,95 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 51.95,
          display: "£51.95",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 42.95,
          display: "£42.95",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 60.95,
          display: "60,95 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 42.95,
          display: "£42.95",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 49.95,
          display: "USD49.95",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 76.95,
          display: "A$76.95",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 60.95,
          display: "60,95 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 61.95,
          display: "61,95 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 48.95,
          display: "48,95 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 55.44,
          display: "$55.44",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 59.95,
          display: "€59.95",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 42.95,
          display: "£42.95",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 58.94,
          display: "US$ 58.94",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 57.44,
          display: "US$57,44",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 60.95,
          display: "€60.95",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 59.95,
          display: "59,95 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 42.95,
          display: "£42.95",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 54.95,
          display: "$54.95",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 76.95,
          display: "$76.95",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 54.95,
          display: "US$54.95",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 56.95,
          display: "€56.95",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 58.95,
          display: "58,95 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 57.95,
          display: "€57.95",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 49.95,
          display: "USD49.95",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 52.95,
          display: "US$52.95",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 76.95,
          display: "$76.95",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 58.95,
          display: "€ 58,95",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 60.95,
          display: "€ 60,95",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 76.95,
          display: "$76.95",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 57.44,
          display: "US$57.44",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 49.95,
          display: "USD49.95",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 49.95,
          display: "USD49.95",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 49.95,
          display: "$49.95",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 59.95,
          display: "59,95 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 48.95,
          display: "€48.95",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 49.95,
          display: "US$49.95",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual-multi",
      pricing: {
        PR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 201.6,
          display: "$201.60",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 230.4,
          display: "230,40 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 220.8,
          display: "$220.80",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 205.44,
          display: "US$205.44",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 230.4,
          display: "$230.40",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 228.48,
          display: "US$228,48",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 228.48,
          display: "US$ 228,48",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 205.44,
          display: "$205.44",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 226.56,
          display: "$226,56",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 201.6,
          display: "US$201.60",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 192,
          display: "$192,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 249,
          display: "A$249.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 213.12,
          display: "$213.12",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 226.56,
          display: "US$ 226.56",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 220.8,
          display: "US$220,80",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 211.2,
          display: "$211.20",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 211.2,
          display: "US$211.20",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 203.52,
          display: "US$203.52",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 220.8,
          display: "US$220.80",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "ocr-one",
      pricing: {
        PR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.35,
              discountValueDisplay: "14,35 €",
              unitPrice: 43.65,
              unitPriceDisplay: "43,65 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 50.4,
          display: "$50.40",
          quantityDiscount: {
            1: {
              discountValue: 12.6,
              discountValueDisplay: "$12.60",
              unitPrice: 37.8,
              unitPriceDisplay: "$37.80",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 48,
          display: "48,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "12,00 US$",
              unitPrice: 36,
              unitPriceDisplay: "36,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 48,
          display: "US$ 48,00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$ 12,00",
              unitPrice: 36,
              unitPriceDisplay: "US$ 36,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 56,
          display: "€ 56,00",
          quantityDiscount: {
            1: {
              discountValue: 14,
              discountValueDisplay: "€ 14,00",
              unitPrice: 42,
              unitPriceDisplay: "€ 42,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 82,
          display: "$82.00",
          quantityDiscount: {
            1: {
              discountValue: 20.26,
              discountValueDisplay: "$20.26",
              unitPrice: 61.74,
              unitPriceDisplay: "$61.74",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 13.89,
              discountValueDisplay: "€13.89",
              unitPrice: 42.11,
              unitPriceDisplay: "€42.11",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 56,
          display: "56,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 14,
              discountValueDisplay: "14,00 EUR",
              unitPrice: 42,
              unitPriceDisplay: "42,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.12,
              discountValueDisplay: "14,12 €",
              unitPrice: 42.88,
              unitPriceDisplay: "42,88 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 57.6,
          display: "57,60 $",
          quantityDiscount: {
            1: {
              discountValue: 14.4,
              discountValueDisplay: "14,40 $",
              unitPrice: 43.2,
              unitPriceDisplay: "43,20 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 14,
              discountValueDisplay: "€14.00",
              unitPrice: 42,
              unitPriceDisplay: "€42.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 48,
          display: "USD48,00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "USD12,00",
              unitPrice: 36,
              unitPriceDisplay: "USD36,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 55.2,
          display: "$55.20",
          quantityDiscount: {
            1: {
              discountValue: 13.8,
              discountValueDisplay: "$13.80",
              unitPrice: 41.4,
              unitPriceDisplay: "$41.40",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 48,
          display: "US$ 48,00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$ 12,00",
              unitPrice: 36,
              unitPriceDisplay: "US$ 36,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 59,
          display: "59,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.59,
              discountValueDisplay: "14,59 €",
              unitPrice: 44.41,
              unitPriceDisplay: "44,41 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 51.36,
          display: "US$51.36",
          quantityDiscount: {
            1: {
              discountValue: 12.84,
              discountValueDisplay: "US$12.84",
              unitPrice: 38.52,
              unitPriceDisplay: "US$38.52",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.24,
              discountValueDisplay: "14,24 €",
              unitPrice: 42.76,
              unitPriceDisplay: "42,76 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 57.6,
          display: "$57.60",
          quantityDiscount: {
            1: {
              discountValue: 14.4,
              discountValueDisplay: "$14.40",
              unitPrice: 43.2,
              unitPriceDisplay: "$43.20",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 56,
          display: "56,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14,
              discountValueDisplay: "14,00 €",
              unitPrice: 42,
              unitPriceDisplay: "42,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 68,
          display: "$68.00",
          quantityDiscount: {
            1: {
              discountValue: 16.81,
              discountValueDisplay: "$16.81",
              unitPrice: 51.19,
              unitPriceDisplay: "$51.19",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 18.42,
              discountValueDisplay: "$18.42",
              unitPrice: 55.58,
              unitPriceDisplay: "$55.58",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 48,
          display: "48,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "12,00 US$",
              unitPrice: 36,
              unitPriceDisplay: "36,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 51,
          display: "€ 51,00",
          quantityDiscount: {
            1: {
              discountValue: 12.57,
              discountValueDisplay: "€ 12,57",
              unitPrice: 38.43,
              unitPriceDisplay: "€ 38,43",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 57.12,
          display: "US$57,12",
          quantityDiscount: {
            1: {
              discountValue: 14.28,
              discountValueDisplay: "US$14,28",
              unitPrice: 42.84,
              unitPriceDisplay: "US$42,84",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 57.12,
          display: "US$ 57,12",
          quantityDiscount: {
            1: {
              discountValue: 14.28,
              discountValueDisplay: "US$ 14,28",
              unitPrice: 42.84,
              unitPriceDisplay: "US$ 42,84",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 48,
          display: "USD48,00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "USD12,00",
              unitPrice: 36,
              unitPriceDisplay: "USD36,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 48,
          display: "48,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "12,00 US$",
              unitPrice: 36,
              unitPriceDisplay: "36,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 51.36,
          display: "$51.36",
          quantityDiscount: {
            1: {
              discountValue: 12.84,
              discountValueDisplay: "$12.84",
              unitPrice: 38.52,
              unitPriceDisplay: "$38.52",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 18.42,
              discountValueDisplay: "$18.42",
              unitPrice: 55.58,
              unitPriceDisplay: "$55.58",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 13.89,
              discountValueDisplay: "€13.89",
              unitPrice: 42.11,
              unitPriceDisplay: "€42.11",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.12,
              discountValueDisplay: "14,12 €",
              unitPrice: 42.88,
              unitPriceDisplay: "42,88 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 56.64,
          display: "$56,64",
          quantityDiscount: {
            1: {
              discountValue: 14.16,
              discountValueDisplay: "$14,16",
              unitPrice: 42.48,
              unitPriceDisplay: "$42,48",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 56,
          display: "56,00 €",
          quantityDiscount: {
            1: {
              discountValue: 13.89,
              discountValueDisplay: "13,89 €",
              unitPrice: 42.11,
              unitPriceDisplay: "42,11 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 18.42,
              discountValueDisplay: "$18.42",
              unitPrice: 55.58,
              unitPriceDisplay: "$55.58",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 50.4,
          display: "US$50.40",
          quantityDiscount: {
            1: {
              discountValue: 12.6,
              discountValueDisplay: "US$12.60",
              unitPrice: 37.8,
              unitPriceDisplay: "US$37.80",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 59,
          display: "59,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.59,
              discountValueDisplay: "14,59 €",
              unitPrice: 44.41,
              unitPriceDisplay: "44,41 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 48,
          display: "$48,00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12,00",
              unitPrice: 36,
              unitPriceDisplay: "$36,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 14,
              discountValueDisplay: "€14.00",
              unitPrice: 42,
              unitPriceDisplay: "€42.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 48,
          display: "US$ 48,00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$ 12,00",
              unitPrice: 36,
              unitPriceDisplay: "US$ 36,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.12,
              discountValueDisplay: "14,12 €",
              unitPrice: 42.88,
              unitPriceDisplay: "42,88 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 48,
          display: "USD48,00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "USD12,00",
              unitPrice: 36,
              unitPriceDisplay: "USD36,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.47,
              discountValueDisplay: "14,47 €",
              unitPrice: 43.53,
              unitPriceDisplay: "43,53 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 56,
          display: "56,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14,
              discountValueDisplay: "14,00 €",
              unitPrice: 42,
              unitPriceDisplay: "42,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 50,
          display: "£50.00",
          quantityDiscount: {
            1: {
              discountValue: 12.36,
              discountValueDisplay: "£12.36",
              unitPrice: 37.64,
              unitPriceDisplay: "£37.64",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 10.3,
              discountValueDisplay: "£10.30",
              unitPrice: 31.7,
              unitPriceDisplay: "£31.70",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.47,
              discountValueDisplay: "14,47 €",
              unitPrice: 43.53,
              unitPriceDisplay: "43,53 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 10.3,
              discountValueDisplay: "£10.30",
              unitPrice: 31.7,
              unitPriceDisplay: "£31.70",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "USD12.00",
              unitPrice: 36,
              unitPriceDisplay: "USD36.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 74,
          display: "A$74.00",
          quantityDiscount: {
            1: {
              discountValue: 18.42,
              discountValueDisplay: "A$18.42",
              unitPrice: 55.58,
              unitPriceDisplay: "A$55.58",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 59,
          display: "59,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 14.59,
              discountValueDisplay: "14,59 EUR",
              unitPrice: 44.41,
              unitPriceDisplay: "44,41 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 60,
          display: "60,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 14.82,
              discountValueDisplay: "14,82 EUR",
              unitPrice: 45.18,
              unitPriceDisplay: "45,18 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 47,
          display: "47,00 €",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "11,67 €",
              unitPrice: 35.33,
              unitPriceDisplay: "35,33 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 53.28,
          display: "$53.28",
          quantityDiscount: {
            1: {
              discountValue: 13.32,
              discountValueDisplay: "$13.32",
              unitPrice: 39.96,
              unitPriceDisplay: "$39.96",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 58,
          display: "€58.00",
          quantityDiscount: {
            1: {
              discountValue: 14.35,
              discountValueDisplay: "€14.35",
              unitPrice: 43.65,
              unitPriceDisplay: "€43.65",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 10.3,
              discountValueDisplay: "£10.30",
              unitPrice: 31.7,
              unitPriceDisplay: "£31.70",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 56.64,
          display: "US$ 56.64",
          quantityDiscount: {
            1: {
              discountValue: 14.16,
              discountValueDisplay: "US$ 14.16",
              unitPrice: 42.48,
              unitPriceDisplay: "US$ 42.48",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 55.2,
          display: "US$55,20",
          quantityDiscount: {
            1: {
              discountValue: 13.8,
              discountValueDisplay: "US$13,80",
              unitPrice: 41.4,
              unitPriceDisplay: "US$41,40",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 58,
          display: "€58.00",
          quantityDiscount: {
            1: {
              discountValue: 14.47,
              discountValueDisplay: "€14.47",
              unitPrice: 43.53,
              unitPriceDisplay: "€43.53",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.24,
              discountValueDisplay: "14,24 €",
              unitPrice: 42.76,
              unitPriceDisplay: "42,76 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 10.3,
              discountValueDisplay: "£10.30",
              unitPrice: 31.7,
              unitPriceDisplay: "£31.70",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 52.8,
          display: "$52.80",
          quantityDiscount: {
            1: {
              discountValue: 13.2,
              discountValueDisplay: "$13.20",
              unitPrice: 39.6,
              unitPriceDisplay: "$39.60",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 18.42,
              discountValueDisplay: "$18.42",
              unitPrice: 55.58,
              unitPriceDisplay: "$55.58",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 52.8,
          display: "US$52.80",
          quantityDiscount: {
            1: {
              discountValue: 13.2,
              discountValueDisplay: "US$13.20",
              unitPrice: 39.6,
              unitPriceDisplay: "US$39.60",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.12,
              discountValueDisplay: "14,12 €",
              unitPrice: 42.88,
              unitPriceDisplay: "42,88 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 55,
          display: "€55.00",
          quantityDiscount: {
            1: {
              discountValue: 13.65,
              discountValueDisplay: "€13.65",
              unitPrice: 41.35,
              unitPriceDisplay: "€41.35",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.12,
              discountValueDisplay: "14,12 €",
              unitPrice: 42.88,
              unitPriceDisplay: "42,88 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 13.77,
              discountValueDisplay: "€13.77",
              unitPrice: 42.23,
              unitPriceDisplay: "€42.23",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "USD12.00",
              unitPrice: 36,
              unitPriceDisplay: "USD36.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 18.42,
              discountValueDisplay: "$18.42",
              unitPrice: 55.58,
              unitPriceDisplay: "$55.58",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 57,
          display: "€ 57,00",
          quantityDiscount: {
            1: {
              discountValue: 14.12,
              discountValueDisplay: "€ 14,12",
              unitPrice: 42.88,
              unitPriceDisplay: "€ 42,88",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 59,
          display: "€ 59,00",
          quantityDiscount: {
            1: {
              discountValue: 14.59,
              discountValueDisplay: "€ 14,59",
              unitPrice: 44.41,
              unitPriceDisplay: "€ 44,41",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 18.42,
              discountValueDisplay: "$18.42",
              unitPrice: 55.58,
              unitPriceDisplay: "$55.58",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 55.2,
          display: "US$55.20",
          quantityDiscount: {
            1: {
              discountValue: 13.8,
              discountValueDisplay: "US$13.80",
              unitPrice: 41.4,
              unitPriceDisplay: "US$41.40",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "USD12.00",
              unitPrice: 36,
              unitPriceDisplay: "USD36.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "USD12.00",
              unitPrice: 36,
              unitPriceDisplay: "USD36.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "$12.00",
              unitPrice: 36,
              unitPriceDisplay: "$36.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 14.35,
              discountValueDisplay: "14,35 €",
              unitPrice: 43.65,
              unitPriceDisplay: "43,65 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 11.67,
              discountValueDisplay: "€11.67",
              unitPrice: 35.33,
              unitPriceDisplay: "€35.33",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 12,
              discountValueDisplay: "US$12.00",
              unitPrice: 36,
              unitPriceDisplay: "US$36.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-multi",
      pricing: {
        PR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 15.75,
          display: "$15.75",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 15,
          display: "15,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 15,
          display: "US$ 15,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 15,
          display: "15,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 18,
          display: "18,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 15,
          display: "USD15,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 17.25,
          display: "$17.25",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 15,
          display: "US$ 15,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 16.05,
          display: "US$16.05",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 18,
          display: "$18.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 15,
          display: "15,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 17.85,
          display: "US$17,85",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 17.85,
          display: "US$ 17,85",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 15,
          display: "USD15,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 15,
          display: "15,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 16.05,
          display: "$16.05",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 17.7,
          display: "$17,70",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 15.75,
          display: "US$15.75",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 15,
          display: "$15,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 15,
          display: "US$ 15,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 15,
          display: "USD15,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 17,
          display: "A$17.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 15,
          display: "15,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 15,
          display: "15,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 16.65,
          display: "$16.65",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 17.7,
          display: "US$ 17.70",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 17.25,
          display: "US$17,25",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 15,
          display: "£15.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 16.5,
          display: "$16.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 16.5,
          display: "US$16.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 15,
          display: "€ 15,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 17,
          display: "$17.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 17.25,
          display: "US$17.25",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 15,
          display: "USD15.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 15,
          display: "$15.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 15,
          display: "15,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 15,
          display: "€15.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 15,
          display: "US$15.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "edit-text-images",
      pricing: {
        PR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 0,
          display: "0,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 0,
          display: "A$0.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 0,
          display: "US$ 0.00",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-multi-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 21,
          display: "$21.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 20,
          display: "20,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 20,
          display: "US$ 20,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 21,
          display: "21,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 24,
          display: "24,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 20,
          display: "USD20,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 20,
          display: "US$ 20,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 21.4,
          display: "US$21.40",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 24,
          display: "$24.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 20,
          display: "20,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 23.8,
          display: "US$23,80",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 23.8,
          display: "US$ 23,80",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 20,
          display: "USD20,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 20,
          display: "20,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 21.4,
          display: "$21.40",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 23.6,
          display: "$23,60",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 21,
          display: "US$21.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 20,
          display: "$20,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 20,
          display: "US$ 20,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 20,
          display: "USD20,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 23,
          display: "A$23.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 21,
          display: "21,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 21,
          display: "21,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 22.2,
          display: "$22.20",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 23.6,
          display: "US$ 23.60",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 23,
          display: "US$23,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 22,
          display: "$22.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 22,
          display: "US$22.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 23,
          display: "US$23.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "ocr-two",
      pricing: {
        PR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 19.14,
              discountValueDisplay: "19,14 €",
              unitPrice: 38.86,
              unitPriceDisplay: "38,86 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 50.4,
          display: "$50.40",
          quantityDiscount: {
            1: {
              discountValue: 16.8,
              discountValueDisplay: "$16.80",
              unitPrice: 33.6,
              unitPriceDisplay: "$33.60",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 48,
          display: "48,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "16,00 US$",
              unitPrice: 32,
              unitPriceDisplay: "32,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 48,
          display: "US$ 48,00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$ 16,00",
              unitPrice: 32,
              unitPriceDisplay: "US$ 32,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 56,
          display: "€ 56,00",
          quantityDiscount: {
            1: {
              discountValue: 18.67,
              discountValueDisplay: "€ 18,67",
              unitPrice: 37.33,
              unitPriceDisplay: "€ 37,33",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 82,
          display: "$82.00",
          quantityDiscount: {
            1: {
              discountValue: 27.02,
              discountValueDisplay: "$27.02",
              unitPrice: 54.98,
              unitPriceDisplay: "$54.98",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 18.52,
              discountValueDisplay: "€18.52",
              unitPrice: 37.48,
              unitPriceDisplay: "€37.48",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 56,
          display: "56,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 18.67,
              discountValueDisplay: "18,67 EUR",
              unitPrice: 37.33,
              unitPriceDisplay: "37,33 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.83,
              discountValueDisplay: "18,83 €",
              unitPrice: 38.17,
              unitPriceDisplay: "38,17 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 57.6,
          display: "57,60 $",
          quantityDiscount: {
            1: {
              discountValue: 19.2,
              discountValueDisplay: "19,20 $",
              unitPrice: 38.4,
              unitPriceDisplay: "38,40 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 18.67,
              discountValueDisplay: "€18.67",
              unitPrice: 37.33,
              unitPriceDisplay: "€37.33",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 48,
          display: "USD48,00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "USD16,00",
              unitPrice: 32,
              unitPriceDisplay: "USD32,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 55.2,
          display: "$55.20",
          quantityDiscount: {
            1: {
              discountValue: 18.4,
              discountValueDisplay: "$18.40",
              unitPrice: 36.8,
              unitPriceDisplay: "$36.80",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 48,
          display: "US$ 48,00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$ 16,00",
              unitPrice: 32,
              unitPriceDisplay: "US$ 32,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 59,
          display: "59,00 €",
          quantityDiscount: {
            1: {
              discountValue: 19.45,
              discountValueDisplay: "19,45 €",
              unitPrice: 39.55,
              unitPriceDisplay: "39,55 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 51.36,
          display: "US$51.36",
          quantityDiscount: {
            1: {
              discountValue: 17.12,
              discountValueDisplay: "US$17.12",
              unitPrice: 34.24,
              unitPriceDisplay: "US$34.24",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.98,
              discountValueDisplay: "18,98 €",
              unitPrice: 38.02,
              unitPriceDisplay: "38,02 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 57.6,
          display: "$57.60",
          quantityDiscount: {
            1: {
              discountValue: 19.2,
              discountValueDisplay: "$19.20",
              unitPrice: 38.4,
              unitPriceDisplay: "$38.40",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 56,
          display: "56,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.67,
              discountValueDisplay: "18,67 €",
              unitPrice: 37.33,
              unitPriceDisplay: "37,33 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 68,
          display: "$68.00",
          quantityDiscount: {
            1: {
              discountValue: 22.41,
              discountValueDisplay: "$22.41",
              unitPrice: 45.59,
              unitPriceDisplay: "$45.59",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 24.56,
              discountValueDisplay: "$24.56",
              unitPrice: 49.44,
              unitPriceDisplay: "$49.44",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 48,
          display: "48,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "16,00 US$",
              unitPrice: 32,
              unitPriceDisplay: "32,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 51,
          display: "€ 51,00",
          quantityDiscount: {
            1: {
              discountValue: 16.76,
              discountValueDisplay: "€ 16,76",
              unitPrice: 34.24,
              unitPriceDisplay: "€ 34,24",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 57.12,
          display: "US$57,12",
          quantityDiscount: {
            1: {
              discountValue: 19.04,
              discountValueDisplay: "US$19,04",
              unitPrice: 38.08,
              unitPriceDisplay: "US$38,08",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 57.12,
          display: "US$ 57,12",
          quantityDiscount: {
            1: {
              discountValue: 19.04,
              discountValueDisplay: "US$ 19,04",
              unitPrice: 38.08,
              unitPriceDisplay: "US$ 38,08",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 48,
          display: "USD48,00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "USD16,00",
              unitPrice: 32,
              unitPriceDisplay: "USD32,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 48,
          display: "48,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "16,00 US$",
              unitPrice: 32,
              unitPriceDisplay: "32,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 51.36,
          display: "$51.36",
          quantityDiscount: {
            1: {
              discountValue: 17.12,
              discountValueDisplay: "$17.12",
              unitPrice: 34.24,
              unitPriceDisplay: "$34.24",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 24.56,
              discountValueDisplay: "$24.56",
              unitPrice: 49.44,
              unitPriceDisplay: "$49.44",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 18.52,
              discountValueDisplay: "€18.52",
              unitPrice: 37.48,
              unitPriceDisplay: "€37.48",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.83,
              discountValueDisplay: "18,83 €",
              unitPrice: 38.17,
              unitPriceDisplay: "38,17 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 56.64,
          display: "$56,64",
          quantityDiscount: {
            1: {
              discountValue: 18.88,
              discountValueDisplay: "$18,88",
              unitPrice: 37.76,
              unitPriceDisplay: "$37,76",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 56,
          display: "56,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.52,
              discountValueDisplay: "18,52 €",
              unitPrice: 37.48,
              unitPriceDisplay: "37,48 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 24.56,
              discountValueDisplay: "$24.56",
              unitPrice: 49.44,
              unitPriceDisplay: "$49.44",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 50.4,
          display: "US$50.40",
          quantityDiscount: {
            1: {
              discountValue: 16.8,
              discountValueDisplay: "US$16.80",
              unitPrice: 33.6,
              unitPriceDisplay: "US$33.60",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 59,
          display: "59,00 €",
          quantityDiscount: {
            1: {
              discountValue: 19.45,
              discountValueDisplay: "19,45 €",
              unitPrice: 39.55,
              unitPriceDisplay: "39,55 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 48,
          display: "$48,00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16,00",
              unitPrice: 32,
              unitPriceDisplay: "$32,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 18.67,
              discountValueDisplay: "€18.67",
              unitPrice: 37.33,
              unitPriceDisplay: "€37.33",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 48,
          display: "US$ 48,00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$ 16,00",
              unitPrice: 32,
              unitPriceDisplay: "US$ 32,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.83,
              discountValueDisplay: "18,83 €",
              unitPrice: 38.17,
              unitPriceDisplay: "38,17 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 48,
          display: "USD48,00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "USD16,00",
              unitPrice: 32,
              unitPriceDisplay: "USD32,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 19.29,
              discountValueDisplay: "19,29 €",
              unitPrice: 38.71,
              unitPriceDisplay: "38,71 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 56,
          display: "56,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.67,
              discountValueDisplay: "18,67 €",
              unitPrice: 37.33,
              unitPriceDisplay: "37,33 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 50,
          display: "£50.00",
          quantityDiscount: {
            1: {
              discountValue: 16.49,
              discountValueDisplay: "£16.49",
              unitPrice: 33.51,
              unitPriceDisplay: "£33.51",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 13.74,
              discountValueDisplay: "£13.74",
              unitPrice: 28.26,
              unitPriceDisplay: "£28.26",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 19.29,
              discountValueDisplay: "19,29 €",
              unitPrice: 38.71,
              unitPriceDisplay: "38,71 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 13.74,
              discountValueDisplay: "£13.74",
              unitPrice: 28.26,
              unitPriceDisplay: "£28.26",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "USD16.00",
              unitPrice: 32,
              unitPriceDisplay: "USD32.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 74,
          display: "A$74.00",
          quantityDiscount: {
            1: {
              discountValue: 24.56,
              discountValueDisplay: "A$24.56",
              unitPrice: 49.44,
              unitPriceDisplay: "A$49.44",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 59,
          display: "59,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 19.45,
              discountValueDisplay: "19,45 EUR",
              unitPrice: 39.55,
              unitPriceDisplay: "39,55 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 60,
          display: "60,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 19.76,
              discountValueDisplay: "19,76 EUR",
              unitPrice: 40.24,
              unitPriceDisplay: "40,24 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 47,
          display: "47,00 €",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "15,56 €",
              unitPrice: 31.44,
              unitPriceDisplay: "31,44 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 53.28,
          display: "$53.28",
          quantityDiscount: {
            1: {
              discountValue: 17.76,
              discountValueDisplay: "$17.76",
              unitPrice: 35.52,
              unitPriceDisplay: "$35.52",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 58,
          display: "€58.00",
          quantityDiscount: {
            1: {
              discountValue: 19.14,
              discountValueDisplay: "€19.14",
              unitPrice: 38.86,
              unitPriceDisplay: "€38.86",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 13.74,
              discountValueDisplay: "£13.74",
              unitPrice: 28.26,
              unitPriceDisplay: "£28.26",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 56.64,
          display: "US$ 56.64",
          quantityDiscount: {
            1: {
              discountValue: 18.88,
              discountValueDisplay: "US$ 18.88",
              unitPrice: 37.76,
              unitPriceDisplay: "US$ 37.76",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 55.2,
          display: "US$55,20",
          quantityDiscount: {
            1: {
              discountValue: 18.4,
              discountValueDisplay: "US$18,40",
              unitPrice: 36.8,
              unitPriceDisplay: "US$36,80",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 58,
          display: "€58.00",
          quantityDiscount: {
            1: {
              discountValue: 19.29,
              discountValueDisplay: "€19.29",
              unitPrice: 38.71,
              unitPriceDisplay: "€38.71",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.98,
              discountValueDisplay: "18,98 €",
              unitPrice: 38.02,
              unitPriceDisplay: "38,02 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 42,
          display: "£42.00",
          quantityDiscount: {
            1: {
              discountValue: 13.74,
              discountValueDisplay: "£13.74",
              unitPrice: 28.26,
              unitPriceDisplay: "£28.26",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 52.8,
          display: "$52.80",
          quantityDiscount: {
            1: {
              discountValue: 17.6,
              discountValueDisplay: "$17.60",
              unitPrice: 35.2,
              unitPriceDisplay: "$35.20",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 24.56,
              discountValueDisplay: "$24.56",
              unitPrice: 49.44,
              unitPriceDisplay: "$49.44",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 52.8,
          display: "US$52.80",
          quantityDiscount: {
            1: {
              discountValue: 17.6,
              discountValueDisplay: "US$17.60",
              unitPrice: 35.2,
              unitPriceDisplay: "US$35.20",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.83,
              discountValueDisplay: "18,83 €",
              unitPrice: 38.17,
              unitPriceDisplay: "38,17 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 55,
          display: "€55.00",
          quantityDiscount: {
            1: {
              discountValue: 18.21,
              discountValueDisplay: "€18.21",
              unitPrice: 36.79,
              unitPriceDisplay: "€36.79",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 57,
          display: "57,00 €",
          quantityDiscount: {
            1: {
              discountValue: 18.83,
              discountValueDisplay: "18,83 €",
              unitPrice: 38.17,
              unitPriceDisplay: "38,17 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 56,
          display: "€56.00",
          quantityDiscount: {
            1: {
              discountValue: 18.36,
              discountValueDisplay: "€18.36",
              unitPrice: 37.64,
              unitPriceDisplay: "€37.64",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "USD16.00",
              unitPrice: 32,
              unitPriceDisplay: "USD32.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 24.56,
              discountValueDisplay: "$24.56",
              unitPrice: 49.44,
              unitPriceDisplay: "$49.44",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 57,
          display: "€ 57,00",
          quantityDiscount: {
            1: {
              discountValue: 18.83,
              discountValueDisplay: "€ 18,83",
              unitPrice: 38.17,
              unitPriceDisplay: "€ 38,17",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 59,
          display: "€ 59,00",
          quantityDiscount: {
            1: {
              discountValue: 19.45,
              discountValueDisplay: "€ 19,45",
              unitPrice: 39.55,
              unitPriceDisplay: "€ 39,55",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 74,
          display: "$74.00",
          quantityDiscount: {
            1: {
              discountValue: 24.56,
              discountValueDisplay: "$24.56",
              unitPrice: 49.44,
              unitPriceDisplay: "$49.44",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 55.2,
          display: "US$55.20",
          quantityDiscount: {
            1: {
              discountValue: 18.4,
              discountValueDisplay: "US$18.40",
              unitPrice: 36.8,
              unitPriceDisplay: "US$36.80",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "USD16.00",
              unitPrice: 32,
              unitPriceDisplay: "USD32.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 48,
          display: "USD48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "USD16.00",
              unitPrice: 32,
              unitPriceDisplay: "USD32.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 48,
          display: "$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "$16.00",
              unitPrice: 32,
              unitPriceDisplay: "$32.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 58,
          display: "58,00 €",
          quantityDiscount: {
            1: {
              discountValue: 19.14,
              discountValueDisplay: "19,14 €",
              unitPrice: 38.86,
              unitPriceDisplay: "38,86 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 47,
          display: "€47.00",
          quantityDiscount: {
            1: {
              discountValue: 15.56,
              discountValueDisplay: "€15.56",
              unitPrice: 31.44,
              unitPriceDisplay: "€31.44",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 48,
          display: "US$48.00",
          quantityDiscount: {
            1: {
              discountValue: 16,
              discountValueDisplay: "US$16.00",
              unitPrice: 32,
              unitPriceDisplay: "US$32.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-multi-uk",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 8,
          display: "A$8.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-two-year-multi",
      pricing: {
        PR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 230,
          display: "230,00 €",
          quantityDiscount: {
            1: {
              discountValue: 114.81,
              discountValueDisplay: "114,81 €",
              unitPrice: 115.19,
              unitPriceDisplay: "115,19 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 201.6,
          display: "$201.60",
          quantityDiscount: {
            1: {
              discountValue: 100.8,
              discountValueDisplay: "$100.80",
              unitPrice: 100.8,
              unitPriceDisplay: "$100.80",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "96,00 US$",
              unitPrice: 96,
              unitPriceDisplay: "96,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$ 96,00",
              unitPrice: 96,
              unitPriceDisplay: "US$ 96,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 225,
          display: "€ 225,00",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "€ 112,01",
              unitPrice: 112.99,
              unitPriceDisplay: "€ 112,99",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 325,
          display: "$325.00",
          quantityDiscount: {
            1: {
              discountValue: 162.09,
              discountValueDisplay: "$162.09",
              unitPrice: 162.91,
              unitPriceDisplay: "$162.91",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 223,
          display: "€223.00",
          quantityDiscount: {
            1: {
              discountValue: 111.07,
              discountValueDisplay: "€111.07",
              unitPrice: 111.93,
              unitPriceDisplay: "€111.93",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 225,
          display: "225,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "112,01 EUR",
              unitPrice: 112.99,
              unitPriceDisplay: "112,99 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 230.4,
          display: "230,40 $",
          quantityDiscount: {
            1: {
              discountValue: 115.2,
              discountValueDisplay: "115,20 $",
              unitPrice: 115.2,
              unitPriceDisplay: "115,20 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 225,
          display: "€225.00",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "€112.01",
              unitPrice: 112.99,
              unitPriceDisplay: "€112.99",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96,00",
              unitPrice: 96,
              unitPriceDisplay: "USD96,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 220.8,
          display: "$220.80",
          quantityDiscount: {
            1: {
              discountValue: 110.4,
              discountValueDisplay: "$110.40",
              unitPrice: 110.4,
              unitPriceDisplay: "$110.40",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$ 96,00",
              unitPrice: 96,
              unitPriceDisplay: "US$ 96,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 234,
          display: "234,00 €",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "116,68 €",
              unitPrice: 117.32,
              unitPriceDisplay: "117,32 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 205.44,
          display: "US$205.44",
          quantityDiscount: {
            1: {
              discountValue: 102.72,
              discountValueDisplay: "US$102.72",
              unitPrice: 102.72,
              unitPriceDisplay: "US$102.72",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 228,
          display: "228,00 €",
          quantityDiscount: {
            1: {
              discountValue: 113.87,
              discountValueDisplay: "113,87 €",
              unitPrice: 114.13,
              unitPriceDisplay: "114,13 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 230.4,
          display: "$230.40",
          quantityDiscount: {
            1: {
              discountValue: 115.2,
              discountValueDisplay: "$115.20",
              unitPrice: 115.2,
              unitPriceDisplay: "$115.20",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 225,
          display: "225,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "112,01 €",
              unitPrice: 112.99,
              unitPriceDisplay: "112,99 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 269,
          display: "$269.00",
          quantityDiscount: {
            1: {
              discountValue: 134.48,
              discountValueDisplay: "$134.48",
              unitPrice: 134.52,
              unitPriceDisplay: "$134.52",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "96,00 US$",
              unitPrice: 96,
              unitPriceDisplay: "96,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 202,
          display: "€ 202,00",
          quantityDiscount: {
            1: {
              discountValue: 100.53,
              discountValueDisplay: "€ 100,53",
              unitPrice: 101.47,
              unitPriceDisplay: "€ 101,47",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 228.48,
          display: "US$228,48",
          quantityDiscount: {
            1: {
              discountValue: 114.24,
              discountValueDisplay: "US$114,24",
              unitPrice: 114.24,
              unitPriceDisplay: "US$114,24",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 228.48,
          display: "US$ 228,48",
          quantityDiscount: {
            1: {
              discountValue: 114.24,
              discountValueDisplay: "US$ 114,24",
              unitPrice: 114.24,
              unitPriceDisplay: "US$ 114,24",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96,00",
              unitPrice: 96,
              unitPriceDisplay: "USD96,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "96,00 US$",
              unitPrice: 96,
              unitPriceDisplay: "96,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 205.44,
          display: "$205.44",
          quantityDiscount: {
            1: {
              discountValue: 102.72,
              discountValueDisplay: "$102.72",
              unitPrice: 102.72,
              unitPriceDisplay: "$102.72",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 223,
          display: "€223.00",
          quantityDiscount: {
            1: {
              discountValue: 111.07,
              discountValueDisplay: "€111.07",
              unitPrice: 111.93,
              unitPriceDisplay: "€111.93",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 226.56,
          display: "$226,56",
          quantityDiscount: {
            1: {
              discountValue: 113.28,
              discountValueDisplay: "$113,28",
              unitPrice: 113.28,
              unitPriceDisplay: "$113,28",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 223,
          display: "223,00 €",
          quantityDiscount: {
            1: {
              discountValue: 111.07,
              discountValueDisplay: "111,07 €",
              unitPrice: 111.93,
              unitPriceDisplay: "111,93 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 201.6,
          display: "US$201.60",
          quantityDiscount: {
            1: {
              discountValue: 100.8,
              discountValueDisplay: "US$100.80",
              unitPrice: 100.8,
              unitPriceDisplay: "US$100.80",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 234,
          display: "234,00 €",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "116,68 €",
              unitPrice: 117.32,
              unitPriceDisplay: "117,32 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 192,
          display: "$192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96,00",
              unitPrice: 96,
              unitPriceDisplay: "$96,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 225,
          display: "€225.00",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "€112.01",
              unitPrice: 112.99,
              unitPriceDisplay: "€112.99",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$ 96,00",
              unitPrice: 96,
              unitPriceDisplay: "US$ 96,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96,00",
              unitPrice: 96,
              unitPriceDisplay: "USD96,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 232,
          display: "232,00 €",
          quantityDiscount: {
            1: {
              discountValue: 115.74,
              discountValueDisplay: "115,74 €",
              unitPrice: 116.26,
              unitPriceDisplay: "116,26 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 225,
          display: "225,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "112,01 €",
              unitPrice: 112.99,
              unitPriceDisplay: "112,99 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 198,
          display: "£198.00",
          quantityDiscount: {
            1: {
              discountValue: 98.93,
              discountValueDisplay: "£98.93",
              unitPrice: 99.07,
              unitPriceDisplay: "£99.07",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 232,
          display: "232,00 €",
          quantityDiscount: {
            1: {
              discountValue: 115.74,
              discountValueDisplay: "115,74 €",
              unitPrice: 116.26,
              unitPriceDisplay: "116,26 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 295,
          display: "A$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "A$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "A$147.65",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 234,
          display: "234,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "116,68 EUR",
              unitPrice: 117.32,
              unitPriceDisplay: "117,32 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 238,
          display: "238,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 118.54,
              discountValueDisplay: "118,54 EUR",
              unitPrice: 119.46,
              unitPriceDisplay: "119,46 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 187,
          display: "187,00 €",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "93,34 €",
              unitPrice: 93.66,
              unitPriceDisplay: "93,66 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 213.12,
          display: "$213.12",
          quantityDiscount: {
            1: {
              discountValue: 106.56,
              discountValueDisplay: "$106.56",
              unitPrice: 106.56,
              unitPriceDisplay: "$106.56",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 230,
          display: "€230.00",
          quantityDiscount: {
            1: {
              discountValue: 114.81,
              discountValueDisplay: "€114.81",
              unitPrice: 115.19,
              unitPriceDisplay: "€115.19",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 226.56,
          display: "US$ 226.56",
          quantityDiscount: {
            1: {
              discountValue: 113.28,
              discountValueDisplay: "US$ 113.28",
              unitPrice: 113.28,
              unitPriceDisplay: "US$ 113.28",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 220.8,
          display: "US$220,80",
          quantityDiscount: {
            1: {
              discountValue: 110.4,
              discountValueDisplay: "US$110,40",
              unitPrice: 110.4,
              unitPriceDisplay: "US$110,40",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 232,
          display: "€232.00",
          quantityDiscount: {
            1: {
              discountValue: 115.74,
              discountValueDisplay: "€115.74",
              unitPrice: 116.26,
              unitPriceDisplay: "€116.26",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 228,
          display: "228,00 €",
          quantityDiscount: {
            1: {
              discountValue: 113.87,
              discountValueDisplay: "113,87 €",
              unitPrice: 114.13,
              unitPriceDisplay: "114,13 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 211.2,
          display: "$211.20",
          quantityDiscount: {
            1: {
              discountValue: 105.6,
              discountValueDisplay: "$105.60",
              unitPrice: 105.6,
              unitPriceDisplay: "$105.60",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 211.2,
          display: "US$211.20",
          quantityDiscount: {
            1: {
              discountValue: 105.6,
              discountValueDisplay: "US$105.60",
              unitPrice: 105.6,
              unitPriceDisplay: "US$105.60",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 219,
          display: "€219.00",
          quantityDiscount: {
            1: {
              discountValue: 109.21,
              discountValueDisplay: "€109.21",
              unitPrice: 109.79,
              unitPriceDisplay: "€109.79",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 221,
          display: "€221.00",
          quantityDiscount: {
            1: {
              discountValue: 110.14,
              discountValueDisplay: "€110.14",
              unitPrice: 110.86,
              unitPriceDisplay: "€110.86",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 226,
          display: "€ 226,00",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "€ 112,94",
              unitPrice: 113.06,
              unitPriceDisplay: "€ 113,06",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 234,
          display: "€ 234,00",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "€ 116,68",
              unitPrice: 117.32,
              unitPriceDisplay: "€ 117,32",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 220.8,
          display: "US$220.80",
          quantityDiscount: {
            1: {
              discountValue: 110.4,
              discountValueDisplay: "US$110.40",
              unitPrice: 110.4,
              unitPriceDisplay: "US$110.40",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 230,
          display: "230,00 €",
          quantityDiscount: {
            1: {
              discountValue: 114.81,
              discountValueDisplay: "114,81 €",
              unitPrice: 115.19,
              unitPriceDisplay: "115,19 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual-multi-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 264.6,
          display: "$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "$233.10",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 302.4,
          display: "302,40 $",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 $",
              unitPrice: 266.4,
              unitPriceDisplay: "266,40 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 289.8,
          display: "$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "$255.30",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 269.64,
          display: "US$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "US$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "US$237.54",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 302.4,
          display: "$302.40",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 266.4,
              unitPriceDisplay: "$266.40",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 299.88,
          display: "US$299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$264,18",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 299.88,
          display: "US$ 299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$ 35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$ 264,18",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 269.64,
          display: "$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "$237.54",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 297.36,
          display: "$297,36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "$35,40",
              unitPrice: 261.96,
              unitPriceDisplay: "$261,96",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 264.6,
          display: "US$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "US$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "US$233.10",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 252,
          display: "$252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30,00",
              unitPrice: 222,
              unitPriceDisplay: "$222,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 321,
          display: "A$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "A$36.00",
              unitPrice: 285,
              unitPriceDisplay: "A$285.00",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 279.72,
          display: "$279.72",
          quantityDiscount: {
            1: {
              discountValue: 33.3,
              discountValueDisplay: "$33.30",
              unitPrice: 246.42,
              unitPriceDisplay: "$246.42",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 297.36,
          display: "US$ 297.36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "US$ 35.40",
              unitPrice: 261.96,
              unitPriceDisplay: "US$ 261.96",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 289.8,
          display: "US$289,80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34,50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255,30",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 277.2,
          display: "$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "$244.20",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 277.2,
          display: "US$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "US$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "US$244.20",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 267.12,
          display: "US$267.12",
          quantityDiscount: {
            1: {
              discountValue: 31.8,
              discountValueDisplay: "US$31.80",
              unitPrice: 235.32,
              unitPriceDisplay: "US$235.32",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 289.8,
          display: "US$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255.30",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "perpetual-pdf",
      pricing: {
        PR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 0,
          display: "0,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 0,
          display: "A$0.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 0,
          display: "US$ 0.00",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual-multi-copy2",
      pricing: {
        PR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 201.6,
          display: "$201.60",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 230.4,
          display: "230,40 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 220.8,
          display: "$220.80",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 205.44,
          display: "US$205.44",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 230.4,
          display: "$230.40",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 228.48,
          display: "US$228,48",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 228.48,
          display: "US$ 228,48",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 205.44,
          display: "$205.44",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 226.56,
          display: "$226,56",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 201.6,
          display: "US$201.60",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 192,
          display: "$192,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 249,
          display: "A$249.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 213.12,
          display: "$213.12",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 226.56,
          display: "US$ 226.56",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 220.8,
          display: "US$220,80",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 211.2,
          display: "$211.20",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 211.2,
          display: "US$211.20",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 203.52,
          display: "US$203.52",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 220.8,
          display: "US$220.80",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual-copy2",
      pricing: {
        PR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 201.6,
          display: "$201.60",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 230.4,
          display: "230,40 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 220.8,
          display: "$220.80",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 205.44,
          display: "US$205.44",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 230.4,
          display: "$230.40",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 228.48,
          display: "US$228,48",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 228.48,
          display: "US$ 228,48",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 205.44,
          display: "$205.44",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 226.56,
          display: "$226,56",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 201.6,
          display: "US$201.60",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 192,
          display: "$192,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 249,
          display: "A$249.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 199,
          display: "199,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 213.12,
          display: "$213.12",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 226.56,
          display: "US$ 226.56",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 220.8,
          display: "US$220,80",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 189,
          display: "£189.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 211.2,
          display: "$211.20",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 211.2,
          display: "US$211.20",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 203.52,
          display: "US$203.52",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 199,
          display: "€ 199,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 249,
          display: "$249.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 220.8,
          display: "US$220.80",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 199,
          display: "199,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 199,
          display: "€199.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual-multi-ocr-copy2",
      pricing: {
        PR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 264.6,
          display: "$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "$233.10",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 302.4,
          display: "302,40 $",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 $",
              unitPrice: 266.4,
              unitPriceDisplay: "266,40 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 289.8,
          display: "$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "$255.30",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 269.64,
          display: "US$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "US$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "US$237.54",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 302.4,
          display: "$302.40",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 266.4,
              unitPriceDisplay: "$266.40",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 299.88,
          display: "US$299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$264,18",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 299.88,
          display: "US$ 299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$ 35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$ 264,18",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 269.64,
          display: "$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "$237.54",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 297.36,
          display: "$297,36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "$35,40",
              unitPrice: 261.96,
              unitPriceDisplay: "$261,96",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 264.6,
          display: "US$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "US$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "US$233.10",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 252,
          display: "$252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30,00",
              unitPrice: 222,
              unitPriceDisplay: "$222,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 321,
          display: "A$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "A$36.00",
              unitPrice: 285,
              unitPriceDisplay: "A$285.00",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 279.72,
          display: "$279.72",
          quantityDiscount: {
            1: {
              discountValue: 33.3,
              discountValueDisplay: "$33.30",
              unitPrice: 246.42,
              unitPriceDisplay: "$246.42",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 297.36,
          display: "US$ 297.36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "US$ 35.40",
              unitPrice: 261.96,
              unitPriceDisplay: "US$ 261.96",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 289.8,
          display: "US$289,80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34,50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255,30",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 277.2,
          display: "$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "$244.20",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 277.2,
          display: "US$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "US$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "US$244.20",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 267.12,
          display: "US$267.12",
          quantityDiscount: {
            1: {
              discountValue: 31.8,
              discountValueDisplay: "US$31.80",
              unitPrice: 235.32,
              unitPriceDisplay: "US$235.32",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 289.8,
          display: "US$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255.30",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-two-year-multi-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 316,
          display: "316,00 €",
          quantityDiscount: {
            1: {
              discountValue: 157.86,
              discountValueDisplay: "157,86 €",
              unitPrice: 158.14,
              unitPriceDisplay: "158,14 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 277.2,
          display: "$277.20",
          quantityDiscount: {
            1: {
              discountValue: 138.6,
              discountValueDisplay: "$138.60",
              unitPrice: 138.6,
              unitPriceDisplay: "$138.60",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 264,
          display: "264,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "132,00 US$",
              unitPrice: 132,
              unitPriceDisplay: "132,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 264,
          display: "US$ 264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$ 132,00",
              unitPrice: 132,
              unitPriceDisplay: "US$ 132,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 309,
          display: "€ 309,00",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "€ 154,01",
              unitPrice: 154.99,
              unitPriceDisplay: "€ 154,99",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 446,
          display: "$446.00",
          quantityDiscount: {
            1: {
              discountValue: 222.87,
              discountValueDisplay: "$222.87",
              unitPrice: 223.13,
              unitPriceDisplay: "$223.13",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 306,
          display: "€306.00",
          quantityDiscount: {
            1: {
              discountValue: 152.72,
              discountValueDisplay: "€152.72",
              unitPrice: 153.28,
              unitPriceDisplay: "€153.28",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 309,
          display: "309,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "154,01 EUR",
              unitPrice: 154.99,
              unitPriceDisplay: "154,99 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 316.8,
          display: "316,80 $",
          quantityDiscount: {
            1: {
              discountValue: 158.4,
              discountValueDisplay: "158,40 $",
              unitPrice: 158.4,
              unitPriceDisplay: "158,40 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 309,
          display: "€309.00",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "€154.01",
              unitPrice: 154.99,
              unitPriceDisplay: "€154.99",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 264,
          display: "USD264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132,00",
              unitPrice: 132,
              unitPriceDisplay: "USD132,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 303.6,
          display: "$303.60",
          quantityDiscount: {
            1: {
              discountValue: 151.8,
              discountValueDisplay: "$151.80",
              unitPrice: 151.8,
              unitPriceDisplay: "$151.80",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 264,
          display: "US$ 264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$ 132,00",
              unitPrice: 132,
              unitPriceDisplay: "US$ 132,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 321,
          display: "321,00 €",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "160,42 €",
              unitPrice: 160.58,
              unitPriceDisplay: "160,58 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 282.48,
          display: "US$282.48",
          quantityDiscount: {
            1: {
              discountValue: 141.24,
              discountValueDisplay: "US$141.24",
              unitPrice: 141.24,
              unitPriceDisplay: "US$141.24",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 314,
          display: "314,00 €",
          quantityDiscount: {
            1: {
              discountValue: 156.57,
              discountValueDisplay: "156,57 €",
              unitPrice: 157.43,
              unitPriceDisplay: "157,43 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 316.8,
          display: "$316.80",
          quantityDiscount: {
            1: {
              discountValue: 158.4,
              discountValueDisplay: "$158.40",
              unitPrice: 158.4,
              unitPriceDisplay: "$158.40",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 309,
          display: "309,00 €",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "154,01 €",
              unitPrice: 154.99,
              unitPriceDisplay: "154,99 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 370,
          display: "$370.00",
          quantityDiscount: {
            1: {
              discountValue: 184.9,
              discountValueDisplay: "$184.90",
              unitPrice: 185.1,
              unitPriceDisplay: "$185.10",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 264,
          display: "264,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "132,00 US$",
              unitPrice: 132,
              unitPriceDisplay: "132,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 277,
          display: "€ 277,00",
          quantityDiscount: {
            1: {
              discountValue: 138.22,
              discountValueDisplay: "€ 138,22",
              unitPrice: 138.78,
              unitPriceDisplay: "€ 138,78",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 314.16,
          display: "US$314,16",
          quantityDiscount: {
            1: {
              discountValue: 157.08,
              discountValueDisplay: "US$157,08",
              unitPrice: 157.08,
              unitPriceDisplay: "US$157,08",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 314.16,
          display: "US$ 314,16",
          quantityDiscount: {
            1: {
              discountValue: 157.08,
              discountValueDisplay: "US$ 157,08",
              unitPrice: 157.08,
              unitPriceDisplay: "US$ 157,08",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 264,
          display: "USD264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132,00",
              unitPrice: 132,
              unitPriceDisplay: "USD132,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 264,
          display: "264,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "132,00 US$",
              unitPrice: 132,
              unitPriceDisplay: "132,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 282.48,
          display: "$282.48",
          quantityDiscount: {
            1: {
              discountValue: 141.24,
              discountValueDisplay: "$141.24",
              unitPrice: 141.24,
              unitPriceDisplay: "$141.24",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 306,
          display: "€306.00",
          quantityDiscount: {
            1: {
              discountValue: 152.72,
              discountValueDisplay: "€152.72",
              unitPrice: 153.28,
              unitPriceDisplay: "€153.28",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 311.52,
          display: "$311,52",
          quantityDiscount: {
            1: {
              discountValue: 155.76,
              discountValueDisplay: "$155,76",
              unitPrice: 155.76,
              unitPriceDisplay: "$155,76",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 306,
          display: "306,00 €",
          quantityDiscount: {
            1: {
              discountValue: 152.72,
              discountValueDisplay: "152,72 €",
              unitPrice: 153.28,
              unitPriceDisplay: "153,28 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 277.2,
          display: "US$277.20",
          quantityDiscount: {
            1: {
              discountValue: 138.6,
              discountValueDisplay: "US$138.60",
              unitPrice: 138.6,
              unitPriceDisplay: "US$138.60",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 321,
          display: "321,00 €",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "160,42 €",
              unitPrice: 160.58,
              unitPriceDisplay: "160,58 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 264,
          display: "$264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132,00",
              unitPrice: 132,
              unitPriceDisplay: "$132,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 309,
          display: "€309.00",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "€154.01",
              unitPrice: 154.99,
              unitPriceDisplay: "€154.99",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 264,
          display: "US$ 264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$ 132,00",
              unitPrice: 132,
              unitPriceDisplay: "US$ 132,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 264,
          display: "USD264,00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132,00",
              unitPrice: 132,
              unitPriceDisplay: "USD132,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 319,
          display: "319,00 €",
          quantityDiscount: {
            1: {
              discountValue: 159.14,
              discountValueDisplay: "159,14 €",
              unitPrice: 159.86,
              unitPriceDisplay: "159,86 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 309,
          display: "309,00 €",
          quantityDiscount: {
            1: {
              discountValue: 154.01,
              discountValueDisplay: "154,01 €",
              unitPrice: 154.99,
              unitPriceDisplay: "154,99 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 273,
          display: "£273.00",
          quantityDiscount: {
            1: {
              discountValue: 136.02,
              discountValueDisplay: "£136.02",
              unitPrice: 136.98,
              unitPriceDisplay: "£136.98",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 319,
          display: "319,00 €",
          quantityDiscount: {
            1: {
              discountValue: 159.14,
              discountValueDisplay: "159,14 €",
              unitPrice: 159.86,
              unitPriceDisplay: "159,86 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 406,
          display: "A$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "A$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "A$203.39",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 321,
          display: "321,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "160,42 EUR",
              unitPrice: 160.58,
              unitPriceDisplay: "160,58 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 326,
          display: "326,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 162.99,
              discountValueDisplay: "162,99 EUR",
              unitPrice: 163.01,
              unitPriceDisplay: "163,01 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 257,
          display: "257,00 €",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "128,34 €",
              unitPrice: 128.66,
              unitPriceDisplay: "128,66 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 293.04,
          display: "$293.04",
          quantityDiscount: {
            1: {
              discountValue: 146.52,
              discountValueDisplay: "$146.52",
              unitPrice: 146.52,
              unitPriceDisplay: "$146.52",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 316,
          display: "€316.00",
          quantityDiscount: {
            1: {
              discountValue: 157.86,
              discountValueDisplay: "€157.86",
              unitPrice: 158.14,
              unitPriceDisplay: "€158.14",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 311.52,
          display: "US$ 311.52",
          quantityDiscount: {
            1: {
              discountValue: 155.76,
              discountValueDisplay: "US$ 155.76",
              unitPrice: 155.76,
              unitPriceDisplay: "US$ 155.76",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 303.6,
          display: "US$303,60",
          quantityDiscount: {
            1: {
              discountValue: 151.8,
              discountValueDisplay: "US$151,80",
              unitPrice: 151.8,
              unitPriceDisplay: "US$151,80",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 319,
          display: "€319.00",
          quantityDiscount: {
            1: {
              discountValue: 159.14,
              discountValueDisplay: "€159.14",
              unitPrice: 159.86,
              unitPriceDisplay: "€159.86",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 314,
          display: "314,00 €",
          quantityDiscount: {
            1: {
              discountValue: 156.57,
              discountValueDisplay: "156,57 €",
              unitPrice: 157.43,
              unitPriceDisplay: "157,43 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 227,
          display: "£227.00",
          quantityDiscount: {
            1: {
              discountValue: 113.35,
              discountValueDisplay: "£113.35",
              unitPrice: 113.65,
              unitPriceDisplay: "£113.65",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 290.4,
          display: "$290.40",
          quantityDiscount: {
            1: {
              discountValue: 145.2,
              discountValueDisplay: "$145.20",
              unitPrice: 145.2,
              unitPriceDisplay: "$145.20",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 290.4,
          display: "US$290.40",
          quantityDiscount: {
            1: {
              discountValue: 145.2,
              discountValueDisplay: "US$145.20",
              unitPrice: 145.2,
              unitPriceDisplay: "US$145.20",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 301,
          display: "€301.00",
          quantityDiscount: {
            1: {
              discountValue: 150.16,
              discountValueDisplay: "€150.16",
              unitPrice: 150.84,
              unitPriceDisplay: "€150.84",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 311,
          display: "311,00 €",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "155,29 €",
              unitPrice: 155.71,
              unitPriceDisplay: "155,71 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 303,
          display: "€303.00",
          quantityDiscount: {
            1: {
              discountValue: 151.44,
              discountValueDisplay: "€151.44",
              unitPrice: 151.56,
              unitPriceDisplay: "€151.56",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 311,
          display: "€ 311,00",
          quantityDiscount: {
            1: {
              discountValue: 155.29,
              discountValueDisplay: "€ 155,29",
              unitPrice: 155.71,
              unitPriceDisplay: "€ 155,71",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 321,
          display: "€ 321,00",
          quantityDiscount: {
            1: {
              discountValue: 160.42,
              discountValueDisplay: "€ 160,42",
              unitPrice: 160.58,
              unitPriceDisplay: "€ 160,58",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 406,
          display: "$406.00",
          quantityDiscount: {
            1: {
              discountValue: 202.61,
              discountValueDisplay: "$202.61",
              unitPrice: 203.39,
              unitPriceDisplay: "$203.39",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 303.6,
          display: "US$303.60",
          quantityDiscount: {
            1: {
              discountValue: 151.8,
              discountValueDisplay: "US$151.80",
              unitPrice: 151.8,
              unitPriceDisplay: "US$151.80",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 264,
          display: "USD264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "USD132.00",
              unitPrice: 132,
              unitPriceDisplay: "USD132.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 264,
          display: "$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "$132.00",
              unitPrice: 132,
              unitPriceDisplay: "$132.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 316,
          display: "316,00 €",
          quantityDiscount: {
            1: {
              discountValue: 157.86,
              discountValueDisplay: "157,86 €",
              unitPrice: 158.14,
              unitPriceDisplay: "158,14 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 257,
          display: "€257.00",
          quantityDiscount: {
            1: {
              discountValue: 128.34,
              discountValueDisplay: "€128.34",
              unitPrice: 128.66,
              unitPriceDisplay: "€128.66",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 264,
          display: "US$264.00",
          quantityDiscount: {
            1: {
              discountValue: 132,
              discountValueDisplay: "US$132.00",
              unitPrice: 132,
              unitPriceDisplay: "US$132.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 0,
          display: "0,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 0,
          display: "A$0.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 0,
          display: "US$ 0.00",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "lite-feature",
      pricing: {
        PR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 0,
          display: "0,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 0,
          display: "A$0.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 0,
          display: "US$ 0.00",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-pro-lite-m",
      pricing: {
        PR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 0,
          display: "0,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 0,
          display: "A$0.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 0,
          display: "US$ 0.00",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual-ocr-copy2",
      pricing: {
        PR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 264.6,
          display: "$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "$233.10",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 302.4,
          display: "302,40 $",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 $",
              unitPrice: 266.4,
              unitPriceDisplay: "266,40 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 289.8,
          display: "$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "$255.30",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 269.64,
          display: "US$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "US$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "US$237.54",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 302.4,
          display: "$302.40",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 266.4,
              unitPriceDisplay: "$266.40",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 299.88,
          display: "US$299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$264,18",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 299.88,
          display: "US$ 299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$ 35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$ 264,18",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 269.64,
          display: "$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "$237.54",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 297.36,
          display: "$297,36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "$35,40",
              unitPrice: 261.96,
              unitPriceDisplay: "$261,96",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 264.6,
          display: "US$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "US$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "US$233.10",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 252,
          display: "$252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30,00",
              unitPrice: 222,
              unitPriceDisplay: "$222,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 321,
          display: "A$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "A$36.00",
              unitPrice: 285,
              unitPriceDisplay: "A$285.00",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 279.72,
          display: "$279.72",
          quantityDiscount: {
            1: {
              discountValue: 33.3,
              discountValueDisplay: "$33.30",
              unitPrice: 246.42,
              unitPriceDisplay: "$246.42",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 297.36,
          display: "US$ 297.36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "US$ 35.40",
              unitPrice: 261.96,
              unitPriceDisplay: "US$ 261.96",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 289.8,
          display: "US$289,80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34,50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255,30",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 277.2,
          display: "$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "$244.20",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 277.2,
          display: "US$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "US$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "US$244.20",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 267.12,
          display: "US$267.12",
          quantityDiscount: {
            1: {
              discountValue: 31.8,
              discountValueDisplay: "US$31.80",
              unitPrice: 235.32,
              unitPriceDisplay: "US$235.32",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 289.8,
          display: "US$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255.30",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "var2",
      pricing: {
        PR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 0,
          display: "0,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 0,
          display: "A$0.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 0,
          display: "US$ 0.00",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-two-year",
      pricing: {
        PR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 230,
          display: "230,00 €",
          quantityDiscount: {
            1: {
              discountValue: 114.81,
              discountValueDisplay: "114,81 €",
              unitPrice: 115.19,
              unitPriceDisplay: "115,19 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 201.6,
          display: "$201.60",
          quantityDiscount: {
            1: {
              discountValue: 100.8,
              discountValueDisplay: "$100.80",
              unitPrice: 100.8,
              unitPriceDisplay: "$100.80",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "96,00 US$",
              unitPrice: 96,
              unitPriceDisplay: "96,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$ 96,00",
              unitPrice: 96,
              unitPriceDisplay: "US$ 96,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 225,
          display: "€ 225,00",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "€ 112,01",
              unitPrice: 112.99,
              unitPriceDisplay: "€ 112,99",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 325,
          display: "$325.00",
          quantityDiscount: {
            1: {
              discountValue: 162.09,
              discountValueDisplay: "$162.09",
              unitPrice: 162.91,
              unitPriceDisplay: "$162.91",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 223,
          display: "€223.00",
          quantityDiscount: {
            1: {
              discountValue: 111.07,
              discountValueDisplay: "€111.07",
              unitPrice: 111.93,
              unitPriceDisplay: "€111.93",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 225,
          display: "225,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "112,01 EUR",
              unitPrice: 112.99,
              unitPriceDisplay: "112,99 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 230.4,
          display: "230,40 $",
          quantityDiscount: {
            1: {
              discountValue: 115.2,
              discountValueDisplay: "115,20 $",
              unitPrice: 115.2,
              unitPriceDisplay: "115,20 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 225,
          display: "€225.00",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "€112.01",
              unitPrice: 112.99,
              unitPriceDisplay: "€112.99",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96,00",
              unitPrice: 96,
              unitPriceDisplay: "USD96,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 220.8,
          display: "$220.80",
          quantityDiscount: {
            1: {
              discountValue: 110.4,
              discountValueDisplay: "$110.40",
              unitPrice: 110.4,
              unitPriceDisplay: "$110.40",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$ 96,00",
              unitPrice: 96,
              unitPriceDisplay: "US$ 96,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 234,
          display: "234,00 €",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "116,68 €",
              unitPrice: 117.32,
              unitPriceDisplay: "117,32 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 205.44,
          display: "US$205.44",
          quantityDiscount: {
            1: {
              discountValue: 102.72,
              discountValueDisplay: "US$102.72",
              unitPrice: 102.72,
              unitPriceDisplay: "US$102.72",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 228,
          display: "228,00 €",
          quantityDiscount: {
            1: {
              discountValue: 113.87,
              discountValueDisplay: "113,87 €",
              unitPrice: 114.13,
              unitPriceDisplay: "114,13 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 230.4,
          display: "$230.40",
          quantityDiscount: {
            1: {
              discountValue: 115.2,
              discountValueDisplay: "$115.20",
              unitPrice: 115.2,
              unitPriceDisplay: "$115.20",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 225,
          display: "225,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "112,01 €",
              unitPrice: 112.99,
              unitPriceDisplay: "112,99 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 269,
          display: "$269.00",
          quantityDiscount: {
            1: {
              discountValue: 134.48,
              discountValueDisplay: "$134.48",
              unitPrice: 134.52,
              unitPriceDisplay: "$134.52",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "96,00 US$",
              unitPrice: 96,
              unitPriceDisplay: "96,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 202,
          display: "€ 202,00",
          quantityDiscount: {
            1: {
              discountValue: 100.53,
              discountValueDisplay: "€ 100,53",
              unitPrice: 101.47,
              unitPriceDisplay: "€ 101,47",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 228.48,
          display: "US$228,48",
          quantityDiscount: {
            1: {
              discountValue: 114.24,
              discountValueDisplay: "US$114,24",
              unitPrice: 114.24,
              unitPriceDisplay: "US$114,24",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 228.48,
          display: "US$ 228,48",
          quantityDiscount: {
            1: {
              discountValue: 114.24,
              discountValueDisplay: "US$ 114,24",
              unitPrice: 114.24,
              unitPriceDisplay: "US$ 114,24",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96,00",
              unitPrice: 96,
              unitPriceDisplay: "USD96,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 192,
          display: "192,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "96,00 US$",
              unitPrice: 96,
              unitPriceDisplay: "96,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 205.44,
          display: "$205.44",
          quantityDiscount: {
            1: {
              discountValue: 102.72,
              discountValueDisplay: "$102.72",
              unitPrice: 102.72,
              unitPriceDisplay: "$102.72",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 223,
          display: "€223.00",
          quantityDiscount: {
            1: {
              discountValue: 111.07,
              discountValueDisplay: "€111.07",
              unitPrice: 111.93,
              unitPriceDisplay: "€111.93",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 226.56,
          display: "$226,56",
          quantityDiscount: {
            1: {
              discountValue: 113.28,
              discountValueDisplay: "$113,28",
              unitPrice: 113.28,
              unitPriceDisplay: "$113,28",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 223,
          display: "223,00 €",
          quantityDiscount: {
            1: {
              discountValue: 111.07,
              discountValueDisplay: "111,07 €",
              unitPrice: 111.93,
              unitPriceDisplay: "111,93 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 201.6,
          display: "US$201.60",
          quantityDiscount: {
            1: {
              discountValue: 100.8,
              discountValueDisplay: "US$100.80",
              unitPrice: 100.8,
              unitPriceDisplay: "US$100.80",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 234,
          display: "234,00 €",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "116,68 €",
              unitPrice: 117.32,
              unitPriceDisplay: "117,32 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 192,
          display: "$192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96,00",
              unitPrice: 96,
              unitPriceDisplay: "$96,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 225,
          display: "€225.00",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "€112.01",
              unitPrice: 112.99,
              unitPriceDisplay: "€112.99",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 192,
          display: "US$ 192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$ 96,00",
              unitPrice: 96,
              unitPriceDisplay: "US$ 96,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 192,
          display: "USD192,00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96,00",
              unitPrice: 96,
              unitPriceDisplay: "USD96,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 232,
          display: "232,00 €",
          quantityDiscount: {
            1: {
              discountValue: 115.74,
              discountValueDisplay: "115,74 €",
              unitPrice: 116.26,
              unitPriceDisplay: "116,26 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 225,
          display: "225,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.01,
              discountValueDisplay: "112,01 €",
              unitPrice: 112.99,
              unitPriceDisplay: "112,99 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 198,
          display: "£198.00",
          quantityDiscount: {
            1: {
              discountValue: 98.93,
              discountValueDisplay: "£98.93",
              unitPrice: 99.07,
              unitPriceDisplay: "£99.07",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 232,
          display: "232,00 €",
          quantityDiscount: {
            1: {
              discountValue: 115.74,
              discountValueDisplay: "115,74 €",
              unitPrice: 116.26,
              unitPriceDisplay: "116,26 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 295,
          display: "A$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "A$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "A$147.65",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 234,
          display: "234,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "116,68 EUR",
              unitPrice: 117.32,
              unitPriceDisplay: "117,32 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 238,
          display: "238,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 118.54,
              discountValueDisplay: "118,54 EUR",
              unitPrice: 119.46,
              unitPriceDisplay: "119,46 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 187,
          display: "187,00 €",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "93,34 €",
              unitPrice: 93.66,
              unitPriceDisplay: "93,66 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 213.12,
          display: "$213.12",
          quantityDiscount: {
            1: {
              discountValue: 106.56,
              discountValueDisplay: "$106.56",
              unitPrice: 106.56,
              unitPriceDisplay: "$106.56",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 230,
          display: "€230.00",
          quantityDiscount: {
            1: {
              discountValue: 114.81,
              discountValueDisplay: "€114.81",
              unitPrice: 115.19,
              unitPriceDisplay: "€115.19",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 226.56,
          display: "US$ 226.56",
          quantityDiscount: {
            1: {
              discountValue: 113.28,
              discountValueDisplay: "US$ 113.28",
              unitPrice: 113.28,
              unitPriceDisplay: "US$ 113.28",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 220.8,
          display: "US$220,80",
          quantityDiscount: {
            1: {
              discountValue: 110.4,
              discountValueDisplay: "US$110,40",
              unitPrice: 110.4,
              unitPriceDisplay: "US$110,40",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 232,
          display: "€232.00",
          quantityDiscount: {
            1: {
              discountValue: 115.74,
              discountValueDisplay: "€115.74",
              unitPrice: 116.26,
              unitPriceDisplay: "€116.26",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 228,
          display: "228,00 €",
          quantityDiscount: {
            1: {
              discountValue: 113.87,
              discountValueDisplay: "113,87 €",
              unitPrice: 114.13,
              unitPriceDisplay: "114,13 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 165,
          display: "£165.00",
          quantityDiscount: {
            1: {
              discountValue: 82.44,
              discountValueDisplay: "£82.44",
              unitPrice: 82.56,
              unitPriceDisplay: "£82.56",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 211.2,
          display: "$211.20",
          quantityDiscount: {
            1: {
              discountValue: 105.6,
              discountValueDisplay: "$105.60",
              unitPrice: 105.6,
              unitPriceDisplay: "$105.60",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 211.2,
          display: "US$211.20",
          quantityDiscount: {
            1: {
              discountValue: 105.6,
              discountValueDisplay: "US$105.60",
              unitPrice: 105.6,
              unitPriceDisplay: "US$105.60",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 219,
          display: "€219.00",
          quantityDiscount: {
            1: {
              discountValue: 109.21,
              discountValueDisplay: "€109.21",
              unitPrice: 109.79,
              unitPriceDisplay: "€109.79",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 226,
          display: "226,00 €",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "112,94 €",
              unitPrice: 113.06,
              unitPriceDisplay: "113,06 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 221,
          display: "€221.00",
          quantityDiscount: {
            1: {
              discountValue: 110.14,
              discountValueDisplay: "€110.14",
              unitPrice: 110.86,
              unitPriceDisplay: "€110.86",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 226,
          display: "€ 226,00",
          quantityDiscount: {
            1: {
              discountValue: 112.94,
              discountValueDisplay: "€ 112,94",
              unitPrice: 113.06,
              unitPriceDisplay: "€ 113,06",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 234,
          display: "€ 234,00",
          quantityDiscount: {
            1: {
              discountValue: 116.68,
              discountValueDisplay: "€ 116,68",
              unitPrice: 117.32,
              unitPriceDisplay: "€ 117,32",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 295,
          display: "$295.00",
          quantityDiscount: {
            1: {
              discountValue: 147.35,
              discountValueDisplay: "$147.35",
              unitPrice: 147.65,
              unitPriceDisplay: "$147.65",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 220.8,
          display: "US$220.80",
          quantityDiscount: {
            1: {
              discountValue: 110.4,
              discountValueDisplay: "US$110.40",
              unitPrice: 110.4,
              unitPriceDisplay: "US$110.40",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 192,
          display: "USD192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "USD96.00",
              unitPrice: 96,
              unitPriceDisplay: "USD96.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 192,
          display: "$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "$96.00",
              unitPrice: 96,
              unitPriceDisplay: "$96.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 230,
          display: "230,00 €",
          quantityDiscount: {
            1: {
              discountValue: 114.81,
              discountValueDisplay: "114,81 €",
              unitPrice: 115.19,
              unitPriceDisplay: "115,19 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 187,
          display: "€187.00",
          quantityDiscount: {
            1: {
              discountValue: 93.34,
              discountValueDisplay: "€93.34",
              unitPrice: 93.66,
              unitPriceDisplay: "€93.66",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 192,
          display: "US$192.00",
          quantityDiscount: {
            1: {
              discountValue: 96,
              discountValueDisplay: "US$96.00",
              unitPrice: 96,
              unitPriceDisplay: "US$96.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "var1",
      pricing: {
        PR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 0,
          display: "0,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 0,
          display: "0,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 0,
          display: "$0,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 0,
          display: "US$ 0,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 0,
          display: "USD0,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 0,
          display: "A$0.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 0,
          display: "0,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 0,
          display: "US$ 0.00",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 0,
          display: "US$0,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 0,
          display: "£0.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 0,
          display: "€ 0,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 0,
          display: "USD0.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 0,
          display: "$0.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 0,
          display: "0,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 0,
          display: "€0.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 0,
          display: "US$0.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 21,
          display: "$21.00",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 20,
          display: "20,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 20,
          display: "US$ 20,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 21,
          display: "21,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 24,
          display: "24,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 20,
          display: "USD20,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 20,
          display: "US$ 20,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 21.4,
          display: "US$21.40",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 24,
          display: "$24.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 20,
          display: "20,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 23.8,
          display: "US$23,80",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 23.8,
          display: "US$ 23,80",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 20,
          display: "USD20,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 20,
          display: "20,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 21.4,
          display: "$21.40",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 23.6,
          display: "$23,60",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 21,
          display: "US$21.00",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 20,
          display: "$20,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 20,
          display: "US$ 20,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 20,
          display: "USD20,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 23,
          display: "A$23.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 21,
          display: "21,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 21,
          display: "21,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 22.2,
          display: "$22.20",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 23.6,
          display: "US$ 23.60",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 23,
          display: "US$23,00",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 20,
          display: "£20.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 22,
          display: "$22.00",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 22,
          display: "US$22.00",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 21,
          display: "€ 21,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 23,
          display: "$23.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 23,
          display: "US$23.00",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 20,
          display: "USD20.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 20,
          display: "$20.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 21,
          display: "21,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 21,
          display: "€21.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 20,
          display: "US$20.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-one-year-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 138.6,
          display: "$138.60",
          quantityDiscount: {
            1: {
              discountValue: 56.7,
              discountValueDisplay: "$56.70",
              unitPrice: 81.9,
              unitPriceDisplay: "$81.90",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 132,
          display: "132,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "54,00 US$",
              unitPrice: 78,
              unitPriceDisplay: "78,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 132,
          display: "US$ 132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$ 54,00",
              unitPrice: 78,
              unitPriceDisplay: "US$ 78,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€ 56,00",
              unitPrice: 91,
              unitPriceDisplay: "€ 91,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 147,
          display: "147,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 EUR",
              unitPrice: 91,
              unitPriceDisplay: "91,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 158.4,
          display: "158,40 $",
          quantityDiscount: {
            1: {
              discountValue: 64.8,
              discountValueDisplay: "64,80 $",
              unitPrice: 93.6,
              unitPriceDisplay: "93,60 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 132,
          display: "USD132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54,00",
              unitPrice: 78,
              unitPriceDisplay: "USD78,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 151.8,
          display: "$151.80",
          quantityDiscount: {
            1: {
              discountValue: 62.1,
              discountValueDisplay: "$62.10",
              unitPrice: 89.7,
              unitPriceDisplay: "$89.70",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 132,
          display: "US$ 132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$ 54,00",
              unitPrice: 78,
              unitPriceDisplay: "US$ 78,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 141.24,
          display: "US$141.24",
          quantityDiscount: {
            1: {
              discountValue: 57.78,
              discountValueDisplay: "US$57.78",
              unitPrice: 83.46,
              unitPriceDisplay: "US$83.46",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 158.4,
          display: "$158.40",
          quantityDiscount: {
            1: {
              discountValue: 64.8,
              discountValueDisplay: "$64.80",
              unitPrice: 93.6,
              unitPriceDisplay: "$93.60",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 132,
          display: "132,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "54,00 US$",
              unitPrice: 78,
              unitPriceDisplay: "78,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€ 56,00",
              unitPrice: 91,
              unitPriceDisplay: "€ 91,00",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 157.08,
          display: "US$157,08",
          quantityDiscount: {
            1: {
              discountValue: 64.26,
              discountValueDisplay: "US$64,26",
              unitPrice: 92.82,
              unitPriceDisplay: "US$92,82",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 157.08,
          display: "US$ 157,08",
          quantityDiscount: {
            1: {
              discountValue: 64.26,
              discountValueDisplay: "US$ 64,26",
              unitPrice: 92.82,
              unitPriceDisplay: "US$ 92,82",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 132,
          display: "USD132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54,00",
              unitPrice: 78,
              unitPriceDisplay: "USD78,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 132,
          display: "132,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "54,00 US$",
              unitPrice: 78,
              unitPriceDisplay: "78,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 141.24,
          display: "$141.24",
          quantityDiscount: {
            1: {
              discountValue: 57.78,
              discountValueDisplay: "$57.78",
              unitPrice: 83.46,
              unitPriceDisplay: "$83.46",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 155.76,
          display: "$155,76",
          quantityDiscount: {
            1: {
              discountValue: 63.72,
              discountValueDisplay: "$63,72",
              unitPrice: 92.04,
              unitPriceDisplay: "$92,04",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 138.6,
          display: "US$138.60",
          quantityDiscount: {
            1: {
              discountValue: 56.7,
              discountValueDisplay: "US$56.70",
              unitPrice: 81.9,
              unitPriceDisplay: "US$81.90",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 132,
          display: "$132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54,00",
              unitPrice: 78,
              unitPriceDisplay: "$78,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 132,
          display: "US$ 132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$ 54,00",
              unitPrice: 78,
              unitPriceDisplay: "US$ 78,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 132,
          display: "USD132,00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54,00",
              unitPrice: 78,
              unitPriceDisplay: "USD78,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 159,
          display: "A$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "A$68.00",
              unitPrice: 91,
              unitPriceDisplay: "A$91.00",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 147,
          display: "147,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 EUR",
              unitPrice: 91,
              unitPriceDisplay: "91,00 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 147,
          display: "147,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 EUR",
              unitPrice: 91,
              unitPriceDisplay: "91,00 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 146.52,
          display: "$146.52",
          quantityDiscount: {
            1: {
              discountValue: 59.94,
              discountValueDisplay: "$59.94",
              unitPrice: 86.58,
              unitPriceDisplay: "$86.58",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 155.76,
          display: "US$ 155.76",
          quantityDiscount: {
            1: {
              discountValue: 63.72,
              discountValueDisplay: "US$ 63.72",
              unitPrice: 92.04,
              unitPriceDisplay: "US$ 92.04",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 151.8,
          display: "US$151,80",
          quantityDiscount: {
            1: {
              discountValue: 62.1,
              discountValueDisplay: "US$62,10",
              unitPrice: 89.7,
              unitPriceDisplay: "US$89,70",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 132,
          display: "£132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "£54.00",
              unitPrice: 78,
              unitPriceDisplay: "£78.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 145.2,
          display: "$145.20",
          quantityDiscount: {
            1: {
              discountValue: 59.4,
              discountValueDisplay: "$59.40",
              unitPrice: 85.8,
              unitPriceDisplay: "$85.80",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 145.2,
          display: "US$145.20",
          quantityDiscount: {
            1: {
              discountValue: 59.4,
              discountValueDisplay: "US$59.40",
              unitPrice: 85.8,
              unitPriceDisplay: "US$85.80",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€ 56,00",
              unitPrice: 91,
              unitPriceDisplay: "€ 91,00",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 147,
          display: "€ 147,00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€ 56,00",
              unitPrice: 91,
              unitPriceDisplay: "€ 91,00",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 159,
          display: "$159.00",
          quantityDiscount: {
            1: {
              discountValue: 68,
              discountValueDisplay: "$68.00",
              unitPrice: 91,
              unitPriceDisplay: "$91.00",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 151.8,
          display: "US$151.80",
          quantityDiscount: {
            1: {
              discountValue: 62.1,
              discountValueDisplay: "US$62.10",
              unitPrice: 89.7,
              unitPriceDisplay: "US$89.70",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 132,
          display: "USD132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "USD54.00",
              unitPrice: 78,
              unitPriceDisplay: "USD78.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 132,
          display: "$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "$54.00",
              unitPrice: 78,
              unitPriceDisplay: "$78.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 147,
          display: "147,00 €",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "56,00 €",
              unitPrice: 91,
              unitPriceDisplay: "91,00 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 147,
          display: "€147.00",
          quantityDiscount: {
            1: {
              discountValue: 56,
              discountValueDisplay: "€56.00",
              unitPrice: 91,
              unitPriceDisplay: "€91.00",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 132,
          display: "US$132.00",
          quantityDiscount: {
            1: {
              discountValue: 54,
              discountValueDisplay: "US$54.00",
              unitPrice: 78,
              unitPriceDisplay: "US$78.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-perpetual-ocr",
      pricing: {
        PR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        QA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AD: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AE: {
          currency: "USD",
          price: 264.6,
          display: "$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "$233.10",
            },
          },
        },
        AF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        AL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AO: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        AQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        AS: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AT: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        RE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AU: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        AW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        AX: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        AZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        RO: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        RS: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        BD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        RU: {
          currency: "USD",
          price: 302.4,
          display: "302,40 $",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 $",
              unitPrice: 266.4,
              unitPriceDisplay: "266,40 $",
            },
          },
        },
        BF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BG: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        RW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BL: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        BM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BO: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        SA: {
          currency: "USD",
          price: 289.8,
          display: "$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "$255.30",
            },
          },
        },
        BQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SB: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BR: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        SC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        BT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SG: {
          currency: "USD",
          price: 269.64,
          display: "US$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "US$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "US$237.54",
            },
          },
        },
        BW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        BY: {
          currency: "USD",
          price: 302.4,
          display: "$302.40",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 266.4,
              unitPriceDisplay: "$266.40",
            },
          },
        },
        SJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        BZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        SL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        SM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        SN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CA: {
          currency: "CAD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CC: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        SS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ST: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        CF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CH: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        SX: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        SZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CL: {
          currency: "USD",
          price: 299.88,
          display: "US$299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$264,18",
            },
          },
        },
        CM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        CO: {
          currency: "USD",
          price: 299.88,
          display: "US$ 299,88",
          quantityDiscount: {
            1: {
              discountValue: 35.7,
              discountValueDisplay: "US$ 35,70",
              unitPrice: 264.18,
              unitPriceDisplay: "US$ 264,18",
            },
          },
        },
        CR: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        TC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        CV: {
          currency: "USD",
          price: 252,
          display: "252,00 US$",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "30,00 US$",
              unitPrice: 222,
              unitPriceDisplay: "222,00 US$",
            },
          },
        },
        TG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TH: {
          currency: "USD",
          price: 269.64,
          display: "$269.64",
          quantityDiscount: {
            1: {
              discountValue: 32.1,
              discountValueDisplay: "$32.10",
              unitPrice: 237.54,
              unitPriceDisplay: "$237.54",
            },
          },
        },
        CX: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        CY: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        TJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        CZ: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        TR: {
          currency: "USD",
          price: 297.36,
          display: "$297,36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "$35,40",
              unitPrice: 261.96,
              unitPriceDisplay: "$261,96",
            },
          },
        },
        TT: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DE: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        TV: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        TW: {
          currency: "USD",
          price: 264.6,
          display: "US$264.60",
          quantityDiscount: {
            1: {
              discountValue: 31.5,
              discountValueDisplay: "US$31.50",
              unitPrice: 233.1,
              unitPriceDisplay: "US$233.10",
            },
          },
        },
        DJ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        TZ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DK: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        DM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        UA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        DZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EC: {
          currency: "USD",
          price: 252,
          display: "$252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30,00",
              unitPrice: 222,
              unitPriceDisplay: "$222,00",
            },
          },
        },
        US: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        EG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        EH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        UY: {
          currency: "USD",
          price: 252,
          display: "US$ 252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$ 30,00",
              unitPrice: 222,
              unitPriceDisplay: "US$ 222,00",
            },
          },
        },
        UZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VA: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        ER: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ES: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ET: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VE: {
          currency: "USD",
          price: 252,
          display: "USD252,00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30,00",
              unitPrice: 222,
              unitPriceDisplay: "USD222,00",
            },
          },
        },
        VG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        VI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        VU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FI: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        FJ: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        FO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        FR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        WF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GB: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        WS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GD: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GG: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GI: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GL: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        GN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GP: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        GQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GR: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        GS: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        GT: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        GU: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        GY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        HM: {
          currency: "AUD",
          price: 321,
          display: "A$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "A$36.00",
              unitPrice: 285,
              unitPriceDisplay: "A$285.00",
            },
          },
        },
        HN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HR: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        HT: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        YE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        HU: {
          currency: "EUR",
          price: 271,
          display: "271,00 EUR",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 EUR",
              unitPrice: 235,
              unitPriceDisplay: "235,00 EUR",
            },
          },
        },
        IC: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ID: {
          currency: "USD",
          price: 279.72,
          display: "$279.72",
          quantityDiscount: {
            1: {
              discountValue: 33.3,
              discountValueDisplay: "$33.30",
              unitPrice: 246.42,
              unitPriceDisplay: "$246.42",
            },
          },
        },
        YT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IE: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IL: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        IM: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        IN: {
          currency: "USD",
          price: 297.36,
          display: "US$ 297.36",
          quantityDiscount: {
            1: {
              discountValue: 35.4,
              discountValueDisplay: "US$ 35.40",
              unitPrice: 261.96,
              unitPriceDisplay: "US$ 261.96",
            },
          },
        },
        IO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        ZA: {
          currency: "USD",
          price: 289.8,
          display: "US$289,80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34,50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255,30",
            },
          },
        },
        IQ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        IS: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        IT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        ZM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JE: {
          currency: "GBP",
          price: 249,
          display: "£249.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "£30.00",
              unitPrice: 219,
              unitPriceDisplay: "£219.00",
            },
          },
        },
        ZW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JM: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        JO: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        JP: {
          currency: "USD",
          price: 277.2,
          display: "$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "$244.20",
            },
          },
        },
        KE: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KG: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KI: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        KM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KR: {
          currency: "USD",
          price: 277.2,
          display: "US$277.20",
          quantityDiscount: {
            1: {
              discountValue: 33,
              discountValueDisplay: "US$33.00",
              unitPrice: 244.2,
              unitPriceDisplay: "US$244.20",
            },
          },
        },
        KW: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        KY: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        KZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LB: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LC: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LI: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        LR: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        LT: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LU: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        LV: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        LY: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MA: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MC: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MD: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ME: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MF: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MH: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MK: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        ML: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MN: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MO: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MQ: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MR: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MS: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MT: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        MU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MV: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        MW: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        MX: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        MY: {
          currency: "USD",
          price: 267.12,
          display: "US$267.12",
          quantityDiscount: {
            1: {
              discountValue: 31.8,
              discountValueDisplay: "US$31.80",
              unitPrice: 235.32,
              unitPriceDisplay: "US$235.32",
            },
          },
        },
        MZ: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NA: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NC: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NE: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NF: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NI: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NL: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NO: {
          currency: "EUR",
          price: 271,
          display: "€ 271,00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€ 36,00",
              unitPrice: 235,
              unitPriceDisplay: "€ 235,00",
            },
          },
        },
        NP: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        NR: {
          currency: "AUD",
          price: 321,
          display: "$321.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "$36.00",
              unitPrice: 285,
              unitPriceDisplay: "$285.00",
            },
          },
        },
        NU: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        NZ: {
          currency: "USD",
          price: 289.8,
          display: "US$289.80",
          quantityDiscount: {
            1: {
              discountValue: 34.5,
              discountValueDisplay: "US$34.50",
              unitPrice: 255.3,
              unitPriceDisplay: "US$255.30",
            },
          },
        },
        OM: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PA: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PE: {
          currency: "USD",
          price: 252,
          display: "USD252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "USD30.00",
              unitPrice: 222,
              unitPriceDisplay: "USD222.00",
            },
          },
        },
        PF: {
          currency: "USD",
          price: 252,
          display: "$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "$30.00",
              unitPrice: 222,
              unitPriceDisplay: "$222.00",
            },
          },
        },
        PG: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PH: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PK: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
        PL: {
          currency: "EUR",
          price: 271,
          display: "271,00 €",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "36,00 €",
              unitPrice: 235,
              unitPriceDisplay: "235,00 €",
            },
          },
        },
        PM: {
          currency: "EUR",
          price: 271,
          display: "€271.00",
          quantityDiscount: {
            1: {
              discountValue: 36,
              discountValueDisplay: "€36.00",
              unitPrice: 235,
              unitPriceDisplay: "€235.00",
            },
          },
        },
        PN: {
          currency: "USD",
          price: 252,
          display: "US$252.00",
          quantityDiscount: {
            1: {
              discountValue: 30,
              discountValueDisplay: "US$30.00",
              unitPrice: 222,
              unitPriceDisplay: "US$222.00",
            },
          },
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf-monthly-uk",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 8,
          display: "A$8.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 5,
          display: "£5.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 8,
          display: "$8.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
    {
      action: "product.price.getall",
      result: "success",
      product: "pdf",
      pricing: {
        PR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        QA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AD: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AE: {
          currency: "USD",
          price: 5.25,
          display: "$5.25",
          quantityDiscount: {},
        },
        AF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        AL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AO: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        AQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        AS: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AT: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        RE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AU: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        AW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        AX: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        AZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        RO: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        BA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        RS: {
          currency: "EUR",
          price: 6,
          display: "6,00 EUR",
          quantityDiscount: {},
        },
        BD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        RU: {
          currency: "USD",
          price: 6,
          display: "6,00 $",
          quantityDiscount: {},
        },
        BF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BG: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        RW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BL: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        BM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BO: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        SA: {
          currency: "USD",
          price: 5.75,
          display: "$5.75",
          quantityDiscount: {},
        },
        BQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SB: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BR: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        SC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        BT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SE: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        BV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SG: {
          currency: "USD",
          price: 5.35,
          display: "US$5.35",
          quantityDiscount: {},
        },
        BW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SI: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        BY: {
          currency: "USD",
          price: 6,
          display: "$6.00",
          quantityDiscount: {},
        },
        SJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        BZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SK: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        SL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        SM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        SN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CA: {
          currency: "CAD",
          price: 7,
          display: "$7.00",
          quantityDiscount: {},
        },
        SR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CC: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        SS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ST: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        CF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CH: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        SX: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        SZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CL: {
          currency: "USD",
          price: 5.95,
          display: "US$5,95",
          quantityDiscount: {},
        },
        CM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        CO: {
          currency: "USD",
          price: 5.95,
          display: "US$ 5,95",
          quantityDiscount: {},
        },
        CR: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        TC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        CV: {
          currency: "USD",
          price: 5,
          display: "5,00 US$",
          quantityDiscount: {},
        },
        TG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TH: {
          currency: "USD",
          price: 5.35,
          display: "$5.35",
          quantityDiscount: {},
        },
        CX: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        CY: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        TJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        CZ: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        TR: {
          currency: "USD",
          price: 5.9,
          display: "$5,90",
          quantityDiscount: {},
        },
        TT: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DE: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        TV: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        TW: {
          currency: "USD",
          price: 5.25,
          display: "US$5.25",
          quantityDiscount: {},
        },
        DJ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        TZ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DK: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        DM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        UA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        DZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EC: {
          currency: "USD",
          price: 5,
          display: "$5,00",
          quantityDiscount: {},
        },
        US: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        EG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        EH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        UY: {
          currency: "USD",
          price: 5,
          display: "US$ 5,00",
          quantityDiscount: {},
        },
        UZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VA: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        ER: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ES: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ET: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VE: {
          currency: "USD",
          price: 5,
          display: "USD5,00",
          quantityDiscount: {},
        },
        VG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        VI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        VU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FI: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        FJ: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        FO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        FR: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        WF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GB: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        WS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GD: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GE: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GG: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GI: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GL: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        GN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GP: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        GQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GR: {
          currency: "EUR",
          price: 7,
          display: "7,00 €",
          quantityDiscount: {},
        },
        GS: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        GT: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        GU: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        GY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        HM: {
          currency: "AUD",
          price: 10,
          display: "A$10.00",
          quantityDiscount: {},
        },
        HN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HR: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        HT: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        YE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        HU: {
          currency: "EUR",
          price: 7,
          display: "7,00 EUR",
          quantityDiscount: {},
        },
        IC: {
          currency: "EUR",
          price: 5,
          display: "5,00 €",
          quantityDiscount: {},
        },
        ID: {
          currency: "USD",
          price: 5.55,
          display: "$5.55",
          quantityDiscount: {},
        },
        YT: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        IE: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        IL: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        IM: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        IN: {
          currency: "USD",
          price: 5.9,
          display: "US$ 5.90",
          quantityDiscount: {},
        },
        IO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        ZA: {
          currency: "USD",
          price: 5.75,
          display: "US$5,75",
          quantityDiscount: {},
        },
        IQ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        IS: {
          currency: "EUR",
          price: 7,
          display: "€7.00",
          quantityDiscount: {},
        },
        IT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        ZM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JE: {
          currency: "GBP",
          price: 7,
          display: "£7.00",
          quantityDiscount: {},
        },
        ZW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JM: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        JO: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        JP: {
          currency: "USD",
          price: 5.5,
          display: "$5.50",
          quantityDiscount: {},
        },
        KE: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KG: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KI: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        KM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KR: {
          currency: "USD",
          price: 5.5,
          display: "US$5.50",
          quantityDiscount: {},
        },
        KW: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        KY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        KZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LB: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LC: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LI: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        LK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        LR: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        LT: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LU: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        LV: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        LY: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MA: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MC: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MD: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ME: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MF: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MH: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MK: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        ML: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MN: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MO: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MQ: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        MR: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MS: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MT: {
          currency: "EUR",
          price: 6,
          display: "€6.00",
          quantityDiscount: {},
        },
        MU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MV: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        MW: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MX: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        MY: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        MZ: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NA: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NC: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NE: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NF: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NI: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NL: {
          currency: "EUR",
          price: 6,
          display: "€ 6,00",
          quantityDiscount: {},
        },
        NO: {
          currency: "EUR",
          price: 7,
          display: "€ 7,00",
          quantityDiscount: {},
        },
        NP: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        NR: {
          currency: "AUD",
          price: 10,
          display: "$10.00",
          quantityDiscount: {},
        },
        NU: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        NZ: {
          currency: "USD",
          price: 5.75,
          display: "US$5.75",
          quantityDiscount: {},
        },
        OM: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PA: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PE: {
          currency: "USD",
          price: 5,
          display: "USD5.00",
          quantityDiscount: {},
        },
        PF: {
          currency: "USD",
          price: 5,
          display: "$5.00",
          quantityDiscount: {},
        },
        PG: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PH: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PK: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
        PL: {
          currency: "EUR",
          price: 6,
          display: "6,00 €",
          quantityDiscount: {},
        },
        PM: {
          currency: "EUR",
          price: 5,
          display: "€5.00",
          quantityDiscount: {},
        },
        PN: {
          currency: "USD",
          price: 5,
          display: "US$5.00",
          quantityDiscount: {},
        },
      },
    },
  ],
}
